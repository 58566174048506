const mapEmailReportResponse = (data) => {
  if (data.preferences.emails[0].job) {
    const emailsArray = [];
    for (let job of data.preferences.emails[0].job) {
      const root = job;
      // skip this job if there is no report attached
      if (!root.report) {
        continue;
      }
      // skip this job if there report doesn't have a dbid
      if (!root.report[0].$.dbid) {
        continue;
      }
      const jobDbid = root.$.dbid;
      const addresses = root.addresses[0].$.value.split(",");
      const body = root.body[0].$.value;
      const errors =
        root.errors[0].$.value !== ""
          ? root.errors.map((err) => err.$.value)
          : null;
      const executeDate = root.executedate[0].$.value;
      const format = root.format[0].$.value.split(",");
      const frequency = {
        count: root.frequency[0].$.count,
        gap: root.frequency[0].$.gap,
      };
      let period;
      if (root.period) {
        period = { count: root.period[0].$.count, gap: root.period[0].$.gap };
      }
      const name = root.name[0].$.value;
      const subject = root.subject[0].$.value;
      const reportDbid = root.report[0].$.dbid;
      const reportParameters = root.report[0].parameters;
      emailsArray.push({
        jobDbid,
        addresses,
        body,
        errors,
        executeDate,
        format,
        frequency,
        period,
        name,
        subject,
        reportDbid,
        reportParameters,
      });
    }
    return emailsArray;
  } else {
    return null;
  }
};
export default mapEmailReportResponse;
