import { useState, useContext, useEffect } from "react";
import styled from "styled-components";

import { ModalContext } from "context/Modal";
import { LocalizationContext } from "context/Localization";
import { NotificationsContext } from "context/Notifications";

import { objectToXml, xmlToObject } from "tools/xml";
// import { defaultPost } from "tools/axios";
import { useAxios } from "tools/axios";

import { objectToFormData, wait } from "tools/misc";

import mapEmailReportResponse from "mapApi/mapEmailReportResponse";

// react select onChange returns array for multi, object for single
import Select from "react-select";
import Button from "components/Button";
import Loading from "components/Loading";
import Checkbox from "components/Checkbox";
import Creatable from "components/Creatable";

const EmailReports = ({ createNew, reportRequest }) => {
  const { defaultPost } = useAxios();
  const { closeModal } = useContext(ModalContext);
  const { mapi18n } = useContext(LocalizationContext);
  const { addNotification } = useContext(NotificationsContext);
  const [selected, setSelected] = useState(null);
  const [waiting, setWaiting] = useState();
  const [loading, setLoading] = useState(true);

  const options = [
    {
      gap: "d",
      label: mapi18n("prf_lbl_day"),
      options: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" },
        { value: "21", label: "21" },
        { value: "22", label: "22" },
        { value: "23", label: "23" },
        { value: "24", label: "24" },
        { value: "25", label: "25" },
        { value: "26", label: "26" },
        { value: "27", label: "27" },
        { value: "28", label: "28" },
        { value: "29", label: "29" },
        { value: "30", label: "30" },
        { value: "31", label: "31" },
      ],
    },
    {
      gap: "w",
      label: mapi18n("prf_lbl_week"),
      options: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
      ],
    },
    {
      gap: "m",
      label: mapi18n("prf_lbl_month"),
      options: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
      ],
    },
  ];

  // list of email reports
  const [eReports, setEReports] = useState([]);

  // form elements
  const [freqCount, setFreqCount] = useState(options[0].options[0]);
  const [freqCountOptions, setFreqCountOptions] = useState(options[0].options);
  const [freqGap, setFreqGap] = useState(
    options.map((op) => ({ value: op.gap, label: op.label }))[0]
  );
  const [freqGapOptions, setFreqGapOptions] = useState(
    options.map((op) => ({ value: op.gap, label: op.label }))
  );

  const [periodCount, setPeriodCount] = useState(options[0].options[0]);
  const [periodCountOptions, setPeriodCountOptions] = useState(
    options[0].options
  );
  const [periodGap, setPeriodGap] = useState(
    options.map((op) => ({ value: op.gap, label: op.label }))[0]
  );
  const [periodGapOptions, setPeriodGapOptions] = useState(
    options.map((op) => ({ value: op.gap, label: op.label }))
  );

  // [xls, html, csv]
  const [format, setFormat] = useState(new Set());
  const [subject, setSubject] = useState();
  const [description, setDescription] = useState();
  const [addresses, setAddresses] = useState([]);

  const [jobDbid, setJobDbid] = useState(null);
  const [reportDbid, setReportDbid] = useState(null);

  const [executeDate, setExecuteDate] = useState(null);

  const [rawReport, setRawReport] = useState(null);

  const loadReports = async () => {
    try {
      if (!createNew) {
        setLoading(true);
        setSelected(null);

        const response = await defaultPost(
          "preferences/email/read/",
          objectToFormData({ product_id: "si" })
        );

        const responseObject = await xmlToObject(response.data);

        setEReports(mapEmailReportResponse(responseObject));
        setLoading(false);
      }
    } catch (err) {
      addNotification("error", "An error occurred");
      console.error(err);
      closeModal();
    }
  };

  useEffect(() => {
    (async () => {
      await loadReports();
    })();
  }, []);

  const handleFreqGapChange = (value) => {
    setFreqCount(freqCountOptions[0]);
    setFreqCountOptions(options.find((op) => op.gap == value.value).options);
    setFreqGap(value);
  };

  const handlePeriodGapChange = (value) => {
    setPeriodCount(periodCountOptions[0]);
    setPeriodCountOptions(options.find((op) => op.gap == value.value).options);
    setPeriodGap(value);
  };

  const validateForm = () => {
    return false;
  };

  const prepareSaveRequest = (action) => {
    if (addresses.length && subject && format.size) {
      let requestObject = {
        preferences: {
          emails: {
            job: {
              $: {
                dbid: createNew || action !== "save" ? "0" : jobDbid,
              },
              report: {
                $: {
                  dbid: createNew || action !== "save" ? "0" : reportDbid,
                },
                parameters: {
                  $: {
                    id: "main_parameters",
                  },
                },
              },
              operation: {
                $: {
                  value: createNew ? "add" : "edit",
                },
              },
              frequency:
                action === "save"
                  ? {
                      $: {
                        count: freqCount.value,
                        gap: freqGap.value,
                      },
                    }
                  : {
                      $: {
                        gap: "once",
                      },
                    },
              period: {
                $: {
                  count: periodCount.value,
                  gap: periodGap.value,
                },
              },
              format: {
                $: {
                  value: Array.from(format).join(","),
                },
              },
              addresses: {
                $: {
                  value: addresses.map((ad) => ad.value).join(","),
                },
              },
              subject: {
                $: {
                  value: subject,
                },
              },
              body: description,
            },
          },
        },
      };
      if (createNew) {
        for (const [key, value] of Object.entries(reportRequest)) {
          if (key !== "reportset_id") {
            requestObject.preferences.emails.job.report.parameters[key] = {
              $: { value },
            };
          }
        }
      } else {
        requestObject.preferences.emails.job.report.parameters = rawReport[0];
      }
      const requestXml = objectToXml(requestObject);
      return requestXml;
    } else {
      addNotification("warning", "Missing fields");
      throw new Error("Missing fields");
    }
  };

  const prepareDeleteRequest = () => {
    let requestObject = {
      preferences: {
        emails: {
          job: {
            $: {
              dbid: jobDbid,
            },
            operation: {
              $: {
                value: "delete",
              },
            },
          },
        },
      },
    };
    const requestXml = objectToXml(requestObject);
    return requestXml;
  };

  const handleSave = async () => {
    if (validateForm()) {
      return;
    }
    try {
      setWaiting("save");
      const requestXml = prepareSaveRequest("save");
      const response = await defaultPost(
        "preferences/email/save/",
        objectToFormData({ data: requestXml, product_id: "si" })
      );
      setWaiting(null);
      if (!createNew) {
        await loadReports();
      } else {
        closeModal();
      }
    } catch (err) {
      console.error(err);
      setWaiting(null);
    }
  };

  const handleEmailNow = async () => {
    if (validateForm()) {
      return;
    }
    try {
      setWaiting("email_now");
      const requestXml = prepareSaveRequest("now");
      const response = await defaultPost(
        "preferences/email/save/",
        objectToFormData({ data: requestXml, product_id: "si" })
      );
      setWaiting(null);
      addNotification("notification", "Email sent successfully");
      // closeModal();
    } catch (err) {
      console.error(err);
      addNotification("error", err.message);
      setWaiting(null);
    }
  };

  const handleDelete = async () => {
    try {
      setWaiting("delete");
      const requestXml = prepareDeleteRequest();
      const response = await defaultPost(
        "preferences/email/del/",
        objectToFormData({ data: requestXml, product_id: "si" })
      );
      await loadReports();
      setWaiting(null);
    } catch (err) {
      console.error(err);
      setWaiting(null);
    }
    // todo
  };

  ////#endregion

  const handleAddressesChange = (value) => {
    if (value) {
      setAddresses(value);
    } else {
      setAddresses([]);
    }
  };

  const handleFormatCheckbox = (e) => {
    if (format.has(e.target.value)) {
      setFormat((f) => new Set([...f].filter((x) => x !== e.target.value)));
    } else {
      setFormat((f) => new Set([...f, e.target.value]));
    }
  };

  const selectReport = (index) => {
    setSelected(index);
    // set EVERYTHING based on the report
    const current = eReports[index];
    setFreqCountOptions(
      options.find((op) => op.gap == current.frequency.gap).options
    );
    setFreqCount({
      value: current.frequency.count,
      label: current.frequency.count,
    });
    setPeriodCountOptions(
      options.find((op) => op.gap == current.period.gap).options
    );
    setPeriodCount({
      value: current.period.count,
      label: current.period.count,
    });
    const freqgap = options.find((op) => op.gap == current.frequency.gap);
    setFreqGap({ value: freqgap.gap, label: freqgap.label });
    const periodgap = options.find((op) => op.gap == current.period.gap);
    setPeriodGap({ value: periodgap.gap, label: periodgap.label });
    setFormat(new Set(current.format));
    setSubject(current.subject);
    setDescription(current.body);
    setAddresses(current.addresses.map((ad) => ({ value: ad, label: ad })));
    setJobDbid(current.jobDbid);
    setReportDbid(current.reportDbid);
    setRawReport(current.reportParameters);
    if (current.executeDate) {
      setExecuteDate(current.executeDate);
    }
  };

  return (
    <Container createNew={createNew}>
      <div>
        {!createNew && (
          <List>
            <ListHeader>{mapi18n("gen_btn_select")}</ListHeader>
            {loading ? (
              <Loading />
            ) : (
              <InnerList>
                {eReports && eReports.length ? (
                  eReports.map((er, i) => (
                    <ReportSelector
                      key={i}
                      label={`${mapi18n("gen_lbl_rep")}`}
                      subject={er.subject}
                      selected={selected === i}
                      onClick={() => selectReport(i)}
                      mapi18n={mapi18n}
                    />
                  ))
                ) : (
                  <NoEmail>No email reports available</NoEmail>
                )}
              </InnerList>
            )}
          </List>
        )}
        {selected !== null || createNew ? (
          <FormContainer createNew={createNew}>
            <Row>
              <Small>
                <Label>
                  {mapi18n("prf_lbl_emlper")}
                  <span style={{ color: "red" }}> *</span>
                </Label>
                <Select
                  options={periodCountOptions}
                  onChange={(value) => setPeriodCount(value)}
                  value={periodCount}
                />
              </Small>
              <Large>
                <Label>
                  {mapi18n("gen_lbl_tf")}
                  <span style={{ color: "red" }}> *</span>
                </Label>
                <Select
                  options={periodGapOptions}
                  onChange={handlePeriodGapChange}
                  value={periodGap}
                />
              </Large>
            </Row>
            <Row>
              <Small>
                <Label>
                  {mapi18n("prf_lbl_emlfreq")}
                  <span style={{ color: "red" }}> *</span>
                </Label>
                <Select
                  options={freqCountOptions}
                  onChange={(value) => setFreqCount(value)}
                  value={freqCount}
                />
              </Small>
              <Large>
                <Label>
                  {mapi18n("gen_lbl_tf")}
                  <span style={{ color: "red" }}> *</span>
                </Label>
                <Select
                  options={freqGapOptions}
                  onChange={handleFreqGapChange}
                  value={freqGap}
                />
              </Large>
            </Row>
            {executeDate && (
              <Row>
                <Large>
                  <Label>{mapi18n("prf_lbl_emlexdt")}: </Label>
                  <ExecuteDate>{executeDate}</ExecuteDate>
                </Large>
              </Row>
            )}
            <Row>
              <Large>
                <Label>
                  {mapi18n("prf_lbl_emlfmr")}
                  <span style={{ color: "red" }}> *</span>
                </Label>
                <Row>
                  <Checkbox
                    label={"XLS"}
                    value={"xls"}
                    checked={format.has("xls")}
                    onClick={handleFormatCheckbox}
                  />
                  <Checkbox
                    label={"HTML"}
                    value={"html"}
                    checked={format.has("html")}
                    onChange={handleFormatCheckbox}
                  />
                  <Checkbox
                    label={"CSV"}
                    value={"csv"}
                    checked={format.has("csv")}
                    onChange={handleFormatCheckbox}
                  />
                </Row>
              </Large>
            </Row>
            <Row>
              <Full>
                <Label>
                  {mapi18n("prf_lbl_emlsub")}
                  <span style={{ color: "red" }}> *</span>
                </Label>
                <Textarea
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Full>
            </Row>
            <Row>
              <Full>
                <Label>{mapi18n("prf_lbl_desc")}</Label>
                <Textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Full>
            </Row>
            <Row>
              <Full>
                <Label>
                  {mapi18n("prf_lbl_emladd")}
                  <span style={{ color: "red" }}> *</span>
                </Label>
                <Creatable value={addresses} setValue={handleAddressesChange} />
              </Full>
            </Row>
          </FormContainer>
        ) : (
          <NoSelection>
            <span>{"Select an Email Report".toUpperCase()}</span>
          </NoSelection>
        )}
      </div>
      <ActionRow>
        <Button tertiary label={mapi18n("gen_btn_can")} onClick={closeModal} />
        {!createNew && selected !== null && (
          <Button
            secondary
            onClick={handleDelete}
            loading={waiting === "delete"}
            off={selected == null && !createNew}
            label={mapi18n("prf_btn_del")}
          />
        )}
        <Button
          primary
          off={selected == null && !createNew}
          loading={waiting === "save"}
          label={mapi18n("prf_btn_sav")}
          onClick={handleSave}
        />
        <Button
          primary
          off={selected == null && !createNew}
          loading={waiting === "email_now"}
          label={mapi18n("prf_btn_enow")}
          onClick={handleEmailNow}
        />
      </ActionRow>
    </Container>
  );
};

export default EmailReports;

const Container = styled.div`
  place-items: center;
  width: ${(p) => (p.createNew ? "40vw" : "60vw")};
  > div:first-child {
    width: 100%;
    display: flex;
  }
`;

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const List = styled.div`
  width: 33%;
  background-color: #eee;
  margin-right: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

const InnerList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  overflow-y: scroll;
`;

const ListHeader = styled.span`
  padding: 0.5rem;
  text-align: center;
  border-bottom: 3px solid #ccc;
  font-size: var(--font-size-3);
  font-weight: 700;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(p) => (p.createNew ? "100%" : "66%")};
`;

const Row = styled.div`
  display: flex;
`;

const Element = styled.div`
  margin: 0.5rem;
`;

const Small = styled(Element)`
  width: 33%;
`;

const Large = styled(Element)`
  width: 66%;
`;

const Full = styled(Element)`
  width: 100%;
`;

const Label = styled.label``;

const Textarea = styled.textarea`
  padding: 0.5rem;
  width: 100%;
  resize: vertical;
`;

const NoSelection = styled.div`
  width: 66%;
  display: grid;
  place-items: center;
  padding: 3rem;
  > span {
    font-weight: 700;
    color: #aaa;
  }
`;

const NoEmail = styled.span`
  padding: 1rem;
  text-align: center;
  color: #888;
`;

const ExecuteDate = styled.span`
  font-weight: 700;
`;

const ReportSelector = ({ label, subject, selected, createNew, onClick }) => {
  return (
    <Holder selected={selected} onClick={onClick}>
      <div>
        <span>{label}</span>
        <span>{subject}</span>
      </div>
    </Holder>
  );
};

const Holder = styled.div`
  width: 100%;
  display: grid;
  place-items: center;

  padding: 1rem;
  cursor: pointer;

  ${(p) => p.selected && "background-color: var(--color-primary);"}

  &:hover {
    background-color: ${(p) =>
      p.selected ? "var(--color-primary)" : "var(--color-hover-opacity)"};
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    > span:first-child {
      font-weight: 700;
      text-align: center;
      ${(p) => (p.selected ? "color: white;" : null)}
    }
    > span:nth-child(2) {
      font-size: var(--font-size-6);
      text-align: center;
      ${(p) => (p.selected ? "color: white;" : null)}
    }
  }
`;
