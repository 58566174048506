const mapDatesResponse = (data) => {
  const timeframeMapping = data.product.timeframes[0].timeframe.map((el) => ({
    id: el.$.id,
    name: el.$.name,
  }));
  return {
    timeframes: timeframeMapping,
    dateLimits: {
      start: data.product.dates[0].date_begin[0].$.value,
      end: data.product.dates[0].date_end[0].$.value,
    },
  };
};

export default mapDatesResponse;
