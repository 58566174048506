import { useContext, useState } from 'react'

import axios from 'axios'
import Button from 'components/Button'
import { LocalizationContext } from 'context/Localization'
import { ModalContext } from 'context/Modal'
import { NotificationsContext } from 'context/Notifications'
// react select onChange returns array for multi, object for single
import Select from 'react-select'
import styled from 'styled-components'
import { api } from 'tools/axios'
import { objectToFormData } from 'tools/misc'

const triggerDownload = (data, extension, fileName) => {
  let url
  if (extension === 'html') {
    url = window.URL.createObjectURL(new Blob([data]))
  } else {
    url = window.URL.createObjectURL(new Blob([data]))
  }

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.${extension}`) //or any other extension
  document.body.appendChild(link)
  link.click()
  window.URL.revokeObjectURL(url)
}

// uses combination of parent options and drilldownoptions to make a report
const ExportReport = ({
  reportParams,
  reportName,
  report,
  isSessionPath,
  sessionPathRef,
  sessionPathStyles,
}) => {
  const { closeModal } = useContext(ModalContext)
  const { mapi18n } = useContext(LocalizationContext)
  const { addNotification } = useContext(NotificationsContext)
  const [selected, setSelected] = useState()
  const [waiting, setWaiting] = useState(null)
  const [options, _] = useState([
    { value: 'html', label: 'html' },
    { value: 'xls', label: 'xls' },
    { value: 'csv', label: 'csv' },
  ])

  // allowed xls, html,
  const handleSubmit = async () => {
    if (selected) {
      try {
        if (selected === 'html') {
          setWaiting('submit')
          const response = await axios({
            url: 'download.html',
            headers: { 'Content-Type': 'text/html' },
          })
          // Parse HTML
          const parser = new DOMParser()
          let htmlFile = parser.parseFromString(response.data, 'text/html')

          // Find script tag to inject code
          let scriptTag = htmlFile.querySelector('#page-data')

          let chartData
          let chartAxis
          if (report.chart) {
            chartAxis = report.chart.axis
            chartData = report.chart.data
          } else {
            chartAxis = null
            chartData = null
          }
          const tableData = report.table
          const reportTitle = report.name
          const lastUpdated = report.header.option.find(
            (op) => op.type === 'lastupdated'
          ).value
          const dateRange = `${report.parameters.date_begin} - ${report.parameters.date_end}`

          const globals = `
            document.title = "${reportTitle}";
            window.sessionPath = ${isSessionPath};
            window.chart = ${report.chart ? JSON.stringify(report.chart) : null}
            window.chartData = ${
              report.chart ? JSON.stringify(chartData) : null
            };
            window.chartAxis = ${
              report.chart ? JSON.stringify(chartAxis) : null
            };
            window.tableData = ${JSON.stringify(tableData)};
            window.reportTitle = "${reportTitle}";
            window.lastUpdated = "${lastUpdated}";
            window.dateRange = "${dateRange}";
            window.listItems = ${
              report.header.listItems
                ? JSON.stringify(report.header.listItems)
                : null
            }
            window.translations = ${null};
            `

          scriptTag.appendChild(document.createTextNode(globals))
          if (isSessionPath) {
            let sessionTables = sessionPathRef.current.cloneNode(true)
            sessionTables
              .querySelectorAll('[data-collapse-table="true"]')
              .forEach((tab) => {
                tab.style = 'display: block; table-layout: unset;'
                tab.firstChild.style = 'table-layout: unset;'
              })
            htmlFile.querySelector('.report-body').appendChild(sessionTables)
            htmlFile.head.insertAdjacentHTML('beforeend', sessionPathStyles)
          }
          const downloadable = htmlFile.documentElement.innerHTML

          triggerDownload(
            downloadable,
            selected,
            `${reportName}_${reportParams.date_begin} -> ${reportParams.date_end}`
          )
          closeModal()
        } else {
          setWaiting('submit')
          const requestObject = { ...reportParams, type: selected }
          const response = await api({
            method: 'post',
            url: 'report/report/export/',
            data: objectToFormData(requestObject),
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
          })
          triggerDownload(
            response.data,
            selected,
            `${reportName}_${reportParams.date_begin} -> ${reportParams.date_end}`
          )
          closeModal()
        }
      } catch (err) {
        console.error(err)
      }
    } else {
      addNotification('warning', 'Select format')
    }
  }

  const handleTypeChange = (value) => {
    setSelected(value.value)
  }

  return (
    <Container>
      <StyledSelect
        options={options}
        onChange={handleTypeChange}
      ></StyledSelect>
      <ActionRow>
        <Button tertiary label={mapi18n('gen_btn_can')} onClick={closeModal} />
        <Button
          primary
          loading={waiting === 'submit'}
          label={mapi18n('rep_hnt_dl')}
          onClick={handleSubmit}
        />
      </ActionRow>
    </Container>
  )
}

export default ExportReport

const Container = styled.div`
  place-items: center;
  width: 40vw;
`

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`

const StyledSelect = styled(Select)`
  min-width: 35vw;
`
