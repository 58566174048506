const mapFilterlistResponse = (data) => {
  if (data.filterlists.filterlist) {
    const filterlists = data.filterlists.filterlist.map((fl) => ({
      id: fl.$.id,
      name: fl.$.name,
      filters: fl.filter.map((ftr) => ftr.$.id),
    }));
    return filterlists;
  } else {
    return [];
  }
};

export default mapFilterlistResponse;
