import styled from "styled-components";
import { binarify } from "tools/misc";

import Loading from "components/Loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/free-solid-svg-icons";

const Button = ({
  primary,
  secondary,
  tertiary,
  label,
  icon,
  color,
  selected,
  rotate,
  flip,
  loading,
  off,
  ...rest
}) => {
  // converts boolean props to binary for ability to switch below. 4 = primary, 2 = secondary, 1 = tertiary, 0 = default = primary
  const type = binarify(primary, secondary, tertiary);
  return (
    <>
      {
        {
          0: (
            <PrimaryButton
              label={label}
              icon={icon}
              color={color}
              title={label}
              loading={loading}
              off={off}
              {...rest}
            />
          ),
          1: (
            <TertiaryButton
              label={label}
              icon={icon}
              color={color}
              title={label}
              loading={loading}
              off={off}
              {...rest}
            />
          ),
          2: (
            <SecondaryButton
              label={label}
              icon={icon}
              color={color}
              selected={selected}
              rotate={rotate}
              flip={flip}
              title={label}
              loading={loading}
              off={off}
              {...rest}
            />
          ),
          4: (
            <PrimaryButton
              label={label}
              icon={icon}
              color={color}
              title={label}
              loading={loading}
              off={off}
              {...rest}
            />
          ),
        }[type]
      }
    </>
  );
};

export default Button;

const PrimaryButton = ({ label, icon, color, loading, off, ...rest }) => {
  return (
    <>
      {label && icon && (
        <StyledPrimary color={color} disabled={loading || off} {...rest}>
          {loading ? (
            <Loading button />
          ) : (
            <>
              <i>
                <FontAwesomeIcon
                  icon={icon}
                  color={color || "var(--color-button-contrast)"}
                />
              </i>
              <span>{label}</span>
            </>
          )}
        </StyledPrimary>
      )}
      {label && !icon && (
        <StyledPrimary color={color} disabled={loading || off} {...rest}>
          {loading ? <Loading button /> : <span>{label}</span>}
        </StyledPrimary>
      )}
      {!label && icon && (
        <StyledPrimary color={color} disabled={loading || off} {...rest}>
          {loading ? (
            <Loading button />
          ) : (
            <FontAwesomeIcon
              icon={icon}
              color={color || "var(--color-button-contrast)"}
            />
          )}
        </StyledPrimary>
      )}
    </>
  );
};
const SecondaryButton = ({
  label,
  icon,
  color,
  selected,
  rotate,
  flip,
  loading,
  off,
  ...rest
}) => {
  return (
    <>
      {label && icon && (
        <StyledSecondary
          color={color}
          selected={selected}
          disabled={loading || off}
          {...rest}
        >
          {loading ? (
            <Loading button />
          ) : (
            <>
              <i>
                <FontAwesomeIcon
                  icon={icon}
                  rotation={rotate}
                  style={{
                    transform: rotate
                      ? "rotate(90deg) scaleX(-1) scaleY(1.5)"
                      : null,
                  }}
                  color={
                    color || selected
                      ? "var(--color-paper)"
                      : "var(--color-button-primary)"
                  }
                />
              </i>
              <span>{label}</span>
            </>
          )}
        </StyledSecondary>
      )}
      {label && !icon && (
        <StyledSecondary
          color={color}
          selected={selected}
          disabled={loading || off}
          {...rest}
        >
          {loading ? <Loading button /> : <span>{label}</span>}
        </StyledSecondary>
      )}
      {!label && icon && (
        <StyledSecondary
          color={color}
          selected={selected}
          disabled={loading || off}
          {...rest}
        >
          {loading ? (
            <Loading button />
          ) : (
            <FontAwesomeIcon
              icon={icon}
              rotation={rotate}
              style={{
                transform: rotate
                  ? "rotate(90deg) scaleX(-1) scaleY(1.5)"
                  : null,
              }}
              color={
                color || selected
                  ? "var(--color-paper)"
                  : "var(--color-button-primary)"
              }
            />
          )}
        </StyledSecondary>
      )}
    </>
  );
};
const TertiaryButton = ({ label, icon, color, loading, off, ...rest }) => {
  return (
    <>
      {label && icon && (
        <StyledTertiary color={color} disabled={loading || off} {...rest}>
          {loading ? (
            <Loading button />
          ) : (
            <>
              <i>
                <FontAwesomeIcon
                  icon={icon}
                  color={color || "var(--color-button-primary)"}
                />
              </i>
              <span>{label}</span>
            </>
          )}
        </StyledTertiary>
      )}
      {label && !icon && (
        <StyledTertiary color={color} disabled={loading || off} {...rest}>
          {loading ? <Loading button /> : <span>{label}</span>}
        </StyledTertiary>
      )}
      {!label && icon && (
        <StyledTertiary color={color} disabled={loading || off} {...rest}>
          {loading ? (
            <Loading button />
          ) : (
            <FontAwesomeIcon
              icon={icon}
              color={color || "var(--color-button-primary)"}
            />
          )}
        </StyledTertiary>
      )}
    </>
  );
};

export const BaseButton = styled.button`
  border: 0;
  padding: var(--padding-button-default);
  margin: var(--margin-button-default);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > span {
    text-align: center;
    font-weight: 700;
  }
  > i {
    margin: var(--margin-button-icon-default);
  }
`;

const StyledPrimary = styled(BaseButton)`
  background-color: ${(p) =>
    p.color ? p.color : "var(--color-button-primary)"};
  > span {
    color: var(--color-button-contrast);
  }
  > i {
    color: var(--color-button-contrast);
  }
  ${(p) => p.disabled && "background-color: #888 !important;"}
`;

const StyledSecondary = styled(BaseButton)`
  border: 1px solid
    ${(p) => (p.color ? p.color : "var(--color-button-primary)")};
  background: none;
  ${(p) => p.selected && "background-color: var(--color-button-primary);"}
  > span {
    color: ${(p) =>
      p.color
        ? p.color
        : p.selected
        ? "var(--color-paper)"
        : "var(--color-button-primary)"};
  }
  > i {
    color: ${(p) =>
      p.color
        ? p.color
        : p.selected
        ? "var(--color-paper)"
        : "var(--color-button-primary)"};
  }
  &:hover {
    background-color: var(--color-hover-opacity);
  }
`;

const StyledTertiary = styled(BaseButton)`
  background: none;
  > span {
    color: ${(p) =>
      p.disabled ? "#ccc" : p.color ? p.color : "var(--color-button-primary)"};
  }
  > i {
    color: ${(p) =>
      p.disabled ? "#ccc" : p.color ? p.color : "var(--color-button-primary)"};
  }
  ${(p) =>
    !p.disabled && "&:hover { background-color: var(--color-hover-opacity);}"}
`;
