import styled from "styled-components";
import { useState } from "react";

import Button from "components/Button";

import Chart from "components/Chart";
import Table from "components/Table";

import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Dashboard = ({ dashboard, mapi18n, removeFromDashboard }) => {
  return (
    <Container>
      {dashboard ? (
        <>
          {dashboard.map((db, i) => (
            <>
              {db.chart && (
                <DashboardCard
                  key={db.dbTitle + Object.keys(db).length + i + db.reportId}
                  order={i + 1}
                  type={"CHART"}
                  title={db.dbTitle}
                  subtitle={db.dbDateRange}
                  updated={db.dbLastUpdated}
                  chart={db.chart}
                  listItems={db.header.listItems}
                  mapi18n={mapi18n}
                  removeFromDashboard={removeFromDashboard}
                  dashboard={db}
                />
              )}
              {db.table && (
                <DashboardCard
                  key={db.dbTitle + Object.keys(db).length + i + db.reportId}
                  order={i + 1}
                  type={"TABLE"}
                  title={db.dbTitle}
                  subtitle={db.dbDateRange}
                  updated={db.dbLastUpdated}
                  listItems={db.header.listItems}
                  table={db.table}
                  removeFromDashboard={removeFromDashboard}
                  dashboard={db}
                />
              )}
            </>
          ))}
        </>
      ) : (
        <EmptyDashboard>
          <span>No reports have been added to the dashboard</span>
        </EmptyDashboard>
      )}
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  background-color: var(--color-dashboard);
  width: 100%;
  padding: 2rem;
  box-shadow: var(--box-shadow-3);
`;

const EmptyDashboard = styled.div`
  min-height: 65vh;
  display: grid;
  place-items: center;
  > span {
    color: white;
    font-weight: 700;
  }
`;

const DashboardCard = ({
  type,
  title,
  subtitle,
  updated,
  table,
  chart,
  removeFromDashboard,
  listItems,
  dashboard,
  mapi18n,
  order,
}) => {
  const [waiting, setWaiting] = useState(false);
  return (
    <CardContainer>
      <HeaderArea>
        <div>
          <TopBar>
            <span>{title}</span>
            <span>{subtitle && " | "}</span>
            <span>{subtitle}</span>
          </TopBar>
          <InfoBar>
            <span>{updated}</span>
          </InfoBar>
          <SecondRow>
            {listItems ? (
              <div>
                {listItems.map((li) => (
                  <LItem>
                    <span>{li[0] + ": "}</span>
                    <span>{li[1]}</span>
                  </LItem>
                ))}
              </div>
            ) : (
              <div></div>
            )}
          </SecondRow>
        </div>
        <div>
          <Button
            tertiary
            loading={waiting}
            icon={faTrash}
            color={"gray"}
            onClick={() => {
              setWaiting(true);
              removeFromDashboard(order);
            }}
          />
        </div>
      </HeaderArea>
      {type === "CHART" && chart && chart !== "EMPTY_CHART" && (
        <Chart chart={chart} mapi18n={mapi18n} />
      )}
      {chart && chart === "EMPTY_CHART" && <span>Chart is empty</span>}
      {type === "TABLE" && table && table !== "EMPTY_TABLE" && (
        <Table table={table} report={dashboard} />
      )}
      {table && table === "EMPTY_TABLE" && <span>Table is empty</span>}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  background-color: white;
  box-shadow: var(--box-shadow-1);
  margin-bottom: 2rem;
  padding: 1rem;
`;

const SecondRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

const LItem = styled.div`
  > span {
    font-size: var(--font-size-6);
  }
  > span:first-child {
    color: #555;
  }
  > span:nth-child(2) {
    color: #222;
    font-weight: 700;
  }
`;

const HeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TopBar = styled.div`
  margin-bottom: 0.5rem;
  > span:first-child {
    font-size: var(--font-size-3);
  }
  > span:nth-child(3) {
    font-size: var(--font-size-4);
    color: gray;
  }
`;

const InfoBar = styled.div`
  margin-bottom: 0.5rem;
  > span:first-child {
    font-size: var(--font-size-5);
    color: gray;
  }
`;
