import { useState, forwardRef } from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Button from "components/Button";
import Input from "components/Input";

import { faTrash, faGripHorizontal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SortableList = ({ elements, setElements }) => {
  const [key, setKey] = useState(Math.random());
  const [editing, setEditing] = useState(false);
  const handleDragEnd = (result) => {
    const items = Array.from(elements);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setElements(items);
  };

  const handleDeleteElement = (index) => {
    setElements((els) => els.filter((_, i) => i !== index));
  };

  const handleSaveElement = (index, newValue) => {
    setElements((els, i) => {
      let newArray = els;
      newArray.splice(index, 1, { value: newValue, label: newValue });
      return newArray;
    });
    setKey((k) => k + Math.random());
  };

  return (
    <Container key={key}>
      {elements && elements.length ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={"list"}>
            {(provided) => (
              <Ul {...provided.droppableProps} ref={provided.innerRef}>
                {elements.map((t, i) => (
                  <Draggable
                    key={t.value + t.label}
                    draggableId={`${t.value}`}
                    index={i}
                    isDragDisabled={editing}
                  >
                    {(provided) => (
                      <Element
                        index={i}
                        label={t.label}
                        value={t.value}
                        ref={provided.innerRef}
                        globalEditing={editing}
                        setGlobalEditing={setEditing}
                        deleteElement={handleDeleteElement}
                        saveElement={handleSaveElement}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Ul>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <NoElements>
          <span>Empty</span>
        </NoElements>
      )}
    </Container>
  );
};

export default SortableList;

const Element = forwardRef(
  (
    {
      index,
      label,
      value,
      deleteElement,
      saveElement,
      globalEditing,
      setGlobalEditing,
      ...rest
    },
    ref
  ) => {
    const [newValue, setNewValue] = useState(value);
    const [editing, setEditing] = useState(false);

    const handleSaveValue = (index) => {
      saveElement(index, newValue);
      setEditing(false);
      setGlobalEditing(false);
    };

    return (
      <ElementContainer ref={ref} title={value} {...rest}>
        <div>
          <FontAwesomeIcon icon={faGripHorizontal} color={"#bbb"} size={"lg"} />
          {editing ? (
            <>
              <Input
                value={newValue}
                noPadding
                onChange={(e) => setNewValue(e.target.value)}
              />
              <Button
                secondary
                label={"Save"}
                onClick={() => handleSaveValue(index)}
              />
            </>
          ) : (
            <>
              <span>{label}</span>
              <Button
                tertiary
                label={"Edit"}
                off={globalEditing}
                onClick={() => {
                  setEditing(true);
                  setGlobalEditing(true);
                }}
              />
            </>
          )}
        </div>
        <Button
          tertiary
          icon={faTrash}
          off={globalEditing}
          color={"gray"}
          style={{ padding: 0 }}
          onClick={() => deleteElement(index)}
        />
      </ElementContainer>
    );
  }
);

const NoElements = styled.div`
  display: grid;
  place-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
  > span {
    color: #ccc;
  }
`;

const Container = styled.div`
  background-color: var(--color-paper-rough);
  width: 100%;
  overflow-y: scroll;
  max-height: 240px;
  border: 2px solid var(--color-primary);
`;

const ElementContainer = styled.li`
  list-style-type: none;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 5px solid transparent;
  > div {
    display: flex;
    align-items: center;
    > *:first-child {
      margin-left: 0;
    }
    > * {
      margin-left: 1rem;
    }
  }

  &:hover {
    background-color: var(--color-hover-opacity);
  }
`;

const Ul = styled.ul`
  padding: 0;
  margin: 0;
`;
