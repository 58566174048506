import styled from "styled-components";
import { useState } from "react";

const Radio = ({ options, selected, setSelected }) => {
  return (
    <Container>
      {options.map((op) => (
        <RadioButton
          key={op.label}
          selected={selected.value === op.value}
          label={op.label}
          onClick={() => setSelected(op)}
        />
      ))}
    </Container>
  );
};

export default Radio;

const Container = styled.div`
  display: flex;
`;

const RadioButton = ({ selected, label, onClick }) => {
  return (
    <RadioDiv onClick={onClick}>
      <RadioLabel>
        <input readOnly type="radio" checked={selected} />
        <span>{label}</span>
      </RadioLabel>
    </RadioDiv>
  );
};

const RadioDiv = styled.div`
  padding: 0.5rem;
`;

const RadioLabel = styled.label`
  > span {
    margin-left: 0.5rem;
  }
`;
