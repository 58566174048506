import mapReportResponse from "mapApi/mapReportResponse";

const mapDashboardResponse = (data) => {
  let dashboardElements = [];
  if (data.reportset.report) {
    for (let i = 0; i < data.reportset.report.length; i++) {
      let el = mapReportResponse(data.reportset.report[i], null);
      el = {
        ...el,
        dbTitle: data.reportset.report[i].header[0].data[0].option.find(
          (op) => op.$.type == "reportname"
        ).$.value,
        dbLastUpdated: data.reportset.report[i].header[0].data[0].option.find(
          (op) => op.$.type == "lastupdated"
        ).$.value,
        dbDateRange: data.reportset.report[i].header[0].data[0].option.find(
          (op) => op.$.type == "daterange"
        ).$.value,
      };
      dashboardElements.push(el);
    }

    return dashboardElements;
  } else {
    return null;
  }
};

export default mapDashboardResponse;
