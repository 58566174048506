import { useRef, useState, useContext } from "react";
import { useReactToPrint } from "react-to-print";

import dashboardIcon from "../../dashboard.png";

import styled from "styled-components";

import Button, { BaseButton } from "components/Button";
import Chart from "components/Chart";
import Table from "components/Table";
import SessionPathTable from "components/SessionPathTable";
import Input from "components/Input";

import { formatDate } from "tools/misc";

import { DropdownDatePicker } from "components/DatePicker";

import { LocalizationContext } from "context/Localization";
import { ModalContext } from "context/Modal";
import { NotificationsContext } from "context/Notifications";

import AddToDashboard from "components/Modal/components/AddToDashboard";
import ExportReport from "components/Modal/components/ExportReport";
import EmailReports from "components/Modal/components/EmailReports";
import NewReport from "components/Modal/components/NewReport";

import { metrixDateToJsDate } from "tools/misc";

import {
  faPlus,
  faDownload,
  faPrint,
  faMailBulk,
  faAngleDown,
  faAngleUp,
  faSearch,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Report = ({
  report,
  index,
  getReport,
  reportRequest,
  reportOptionalFields,
  dateLimits,
  addToDashboard,
  dashboard,
  filters,
  filterlist,
  reportType,
}) => {
  const componentRef = useRef();
  const sessionRef = useRef();

  const { language, mapi18n } = useContext(LocalizationContext);
  const { openModal } = useContext(ModalContext);
  const { addNotification } = useContext(NotificationsContext);

  const [isSearching, setIsSearching] = useState(false);

  const [printing, setPrinting] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [chartVisible, setChartVisible] = useState(
    report.parameters.display_chart === "true"
  );
  const [tableVisible, setTableVisible] = useState(true);
  const [waiting, setWaiting] = useState(true);

  const [startDate, setStartDate] = useState(
    metrixDateToJsDate(report.parameters.date_begin)
  );
  const [endDate, setEndDate] = useState(
    report.parameters.date_end
      ? metrixDateToJsDate(report.parameters.date_end)
      : new Date()
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${report.name}_${report.parameters.date_begin}-${report.parameters.date_end}`,
  });

  const handleSearchSubmit = async (searchString) => {
    setIsSearching(true);
    await getReport(
      { ...reportRequest, search_string: searchString },
      null,
      reportOptionalFields
    );
    setIsSearching(false);
  };

  const options = [
    {
      type: "todashboard",
      label: "rep_hnt_adddash",
      func: () =>
        openModal(
          <AddToDashboard
            reportRequest={reportRequest}
            dashboard={dashboard}
            addToDashboard={addToDashboard}
          />,
          mapi18n("rep_hnt_adddash")
        ),
      icon: faPlus,
    },
    {
      type: "export",
      label: "rep_hnt_dl",
      func: () => {
        if (report.parameters.report_id === "si_pp_se") {
          // get styles
          let styles = Array.from(
            document.querySelectorAll('[data-emotion="css"]')
          );
          let globalStyle = document.querySelector('[data-styled="active"]');
          let globalStyleString =
            "<style>" + globalStyle.innerHTML + "</style>";
          let stylesArray = styles.map(
            (a) => "<style>" + a.innerHTML + "</style>"
          );

          let styleString = globalStyleString + stylesArray.join("");
          let tableSection = sessionRef.current;

          openModal(
            <ExportReport
              isSessionPath={true}
              sessionPathRef={sessionRef}
              sessionPathStyles={styleString}
              reportParams={reportRequest}
              reportName={
                report.header.option.find((o) => o.type === "reportname").value
              }
              report={report}
            />,
            mapi18n("rep_hnt_dl")
          );
        } else {
          openModal(
            <ExportReport
              isSessionPath={false}
              sessionPathRef={sessionRef}
              reportParams={reportRequest}
              reportName={
                report.header.option.find((o) => o.type === "reportname").value
              }
              report={report}
            />,
            mapi18n("rep_hnt_dl")
          );
        }
      },
      icon: faDownload,
    },
    {
      type: "print",
      label: "rep_hnt_pr",
      func: () => handlePrint(),
      icon: faPrint,
    },
    {
      type: "email",
      label: "prf_lbl_cneml",
      func: () =>
        openModal(
          <EmailReports reportRequest={reportRequest} createNew />,
          mapi18n("prf_lbl_emlexp"),
          mapi18n("prf_lbl_cn")
        ),
      icon: faMailBulk,
    },
  ];

  const updateDateRange = async () => {
    if (startDate && endDate) {
      try {
        setWaiting("date_range");
        let reportParams = {
          ...reportRequest,
          date_begin: formatDate(startDate),
          date_end: formatDate(endDate),
        };
        await getReport(reportParams);
        setWaiting(null);
      } catch (err) {
        addNotification("error", "An error occurred");
        setWaiting(null);
      }
    } else {
      addNotification("warning", "Dates required");
    }
  };

  return (
    <Container ref={componentRef}>
      <SummaryBar>
        <div>
          <TitleArea>
            <h2>
              {report.header.option.find((o) => o.type === "reportname").value}
            </h2>
            <span>
              {report.header.option.find((o) => o.type === "lastupdated").value}
            </span>
          </TitleArea>
          <>
            <div className={"horizontal divider"}>|</div>
            <div>
              <DropdownDatePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                apply={updateDateRange}
                min={metrixDateToJsDate(dateLimits.start)}
                max={metrixDateToJsDate(dateLimits.end)}
                waiting={waiting}
              />
            </div>
          </>
          {reportType && (
            <>
              <div className={"horizontal divider"}>|</div>
              <Button
                secondary
                label={mapi18n("prf_lbl_edrep")}
                icon={faEdit}
                onClick={() =>
                  openModal(
                    <NewReport
                      report={reportType}
                      filters={filters}
                      filterlist={filterlist}
                      dateLimits={dateLimits}
                      getReport={getReport}
                      reportRequest={reportRequest}
                    />,
                    mapi18n("prf_lbl_edrep"),
                    reportType.name
                  )
                }
              >
                Edit Report
              </Button>
            </>
          )}
        </div>
        <div>
          {options
            .filter((o) => report.header.option.find((ho) => ho.type == o.type))
            .map((option, i) => (
              <>
                {option.type === "todashboard" ? (
                  <DashboardSummaryButton
                    key={option.label}
                    title={mapi18n(option.label)}
                    onClick={option.func}
                  >
                    <img src={dashboardIcon} alt="dashboard" />
                  </DashboardSummaryButton>
                ) : (
                  <SummaryButton
                    tertiary
                    language={language}
                    /*label={mapi18n(option.label)}*/
                    title={mapi18n(option.label)}
                    icon={option.icon}
                    onClick={option.func}
                    color={"var(--color-text-default)"}
                    key={option.label}
                  />
                )}
                {i !==
                  options.filter((o) =>
                    report.header.option.find((ho) => ho.type == o.type)
                  ).length -
                    1 && (
                  <div className="horiz divider" key={option.label + "123"}>
                    |
                  </div>
                )}
              </>
            ))}
        </div>
      </SummaryBar>
      <SecondRow>
        {report.header.listItems ? (
          <div>
            {report.header.listItems.map((li, i) => (
              <LItem key={i}>
                <span>{li[0] + ": "}</span>
                {li.map((l, i) => (
                  <>
                    {i ? (
                      <span>
                        {l}
                        {li.length > 2 && i < li.length - 1 && ", "}
                      </span>
                    ) : null}
                  </>
                ))}
              </LItem>
            ))}
          </div>
        ) : (
          <div></div>
        )}
        <Search
          handleSearchSubmit={handleSearchSubmit}
          isSearching={isSearching}
        />
        {/* reportOptionalFields.hasOwnProperty("search_string") && (
          <Search handleSearchSubmit={handleSearchSubmit} />
        ) */}
      </SecondRow>

      {report.chart === "EMPTY_CHART" && report.table === "EMPTY_TABLE" && (
        <span>{mapi18n("msg_cht_nod")}</span>
      )}
      {report.chart && report.chart !== "EMPTY_CHART" && (
        <ChartSection>
          <SectionHeader
            language={language}
            onClick={() => setChartVisible((v) => !v)}
          >
            <span>{mapi18n("prf_lbl_cht")}</span>
            <FontAwesomeIcon
              icon={chartVisible ? faAngleDown : faAngleUp}
              size={"lg"}
            />
          </SectionHeader>
          {chartVisible && (
            <Chart printing={printing} chart={report.chart} mapi18n={mapi18n} />
          )}
        </ChartSection>
      )}
      <PrintPageBreak />
      {report.table && report.table !== "EMPTY_TABLE" && (
        <>
          {Array.isArray(report.table) === false ? (
            <TableSection>
              <SectionHeader
                language={language}
                onClick={() => setTableVisible((v) => !v)}
              >
                <span>{mapi18n("prf_lbl_tab")}</span>
                <FontAwesomeIcon
                  icon={tableVisible ? faAngleDown : faAngleUp}
                  size={"lg"}
                />
              </SectionHeader>
              {tableVisible && (
                <Table
                  table={report.table}
                  report={report}
                  getReport={getReport}
                  reportOptionalFields={reportOptionalFields}
                  reportRequest={reportRequest}
                />
              )}
            </TableSection>
          ) : (
            <div ref={sessionRef}>
              {report.table.map((tab, i) => (
                <TableSection key={i}>
                  <SessionSection
                    language={language}
                    i={i}
                    tab={tab}
                    report={report}
                  />
                </TableSection>
              ))}
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default Report;

const Search = ({ isSearching, handleSearchSubmit }) => {
  const [searchString, setSearchString] = useState("");

  const handleSearchChange = (e) => {
    setSearchString(e.target.value);
  };

  return (
    <SearchBar style={{ width: "340px" }}>
      <Input
        label={"Search"}
        onChange={handleSearchChange}
        value={searchString}
        style={{ minWidth: "100px" }}
      />
      <Button
        tertiary
        icon={faSearch}
        loading={isSearching}
        onClick={() => handleSearchSubmit(searchString)}
      />
    </SearchBar>
  );
};

const SessionSection = ({ language, i, tab, report }) => {
  const [visible, setVisible] = useState(false);
  if (tab.footer === null) {
    return (
      <>
        <SectionHeader
          language={language}
          onClick={() => setVisible((v) => !v)}
        >
          <span>
            {i !== 0 &&
              tab.footer === null &&
              report.table[i - 1].data[0][0].props.children.props.value}
          </span>
          <FontAwesomeIcon
            icon={visible ? faAngleDown : faAngleUp}
            size={"lg"}
          />
        </SectionHeader>
        <SessionPathTable
          collapse
          table={tab}
          report={report}
          visible={visible}
        />
      </>
    );
  } else {
    return <SessionPathTable table={tab} report={report} visible={true} />;
  }
};

const Container = styled.div`
  background-color: var(--color-paper);
  width: 100%;
  padding: 3rem;
  box-shadow: var(--box-shadow-1);
`;

const SecondRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SummaryBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  > div:nth-child(2) {
    display: flex;
    align-items: center;
  }
`;

const LItem = styled.div`
  > span {
    font-size: var(--font-size-6);
  }
  > span:first-child {
    color: #555;
  }
  > span {
    color: #222;
    font-weight: 700;
  }
`;

const SearchBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TitleArea = styled.div`
  padding: 1rem 1rem 1rem 0;
  > h2 {
    font-size: var(--font-size-3);
    color: var(--color-text-primary);
  }
  > span {
    font-size: var(--font-size-6);
    color: var(--color-text-secondary);
  }
`;

const SummaryButton = styled(Button)`
  background: none;
  margin: 0;
  > span {
    color: var(--color-text-default);
    font-weight: 300;
    text-align: left;
    width: ${(p) => (p.language === "ja_JP" ? "fit-content" : "min-content")};
    font-size: var(--font-size-6);
  }

  &:hover {
    background-color: var(--color-hover-opacity);
  }
`;

const DashboardSummaryButton = styled(BaseButton)`
  background: none;
  margin: 0;
  > span {
    color: var(--color-text-default);
    font-weight: 300;
    text-align: left;
    width: ${(p) => (p.language === "ja_JP" ? "fit-content" : "min-content")};
    font-size: var(--font-size-6);
  }
  > img {
    height: 2em;
  }

  &:hover {
    background-color: var(--color-hover-opacity);
  }
`;

const ChartSection = styled.div`
  width: 100%;
`;

const TableSection = styled.div`
  width: 100%;
`;

const SectionHeader = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  margin: 1rem 0;
  > span {
    font-size: var(--font-size-5);
    margin-right: 1rem;
    user-select: none;
  }
`;

const PrintPageBreak = styled.div`
  @media print {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
`;
