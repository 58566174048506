import merge from "lodash.merge";
import { metrixDateToJsDate, numberWithCommas } from "tools/misc";

import { useState, useContext } from "react";
import { ModalContext } from "context/Modal";
import { NotificationsContext } from "context/Notifications";
import { OptionalFieldsContext } from "components/Table";
import { CheckboxContext } from "components/Table";

import { faFileAlt, faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DrilldownReport from "components/Modal/components/DrilldownReport";

// if no reportset, report is object. else if reportset, report is array (hence[0])
// original site always seems to send a randomised reportset_id so maybe this is the best way to go for now.

const mapReportResponse = (data, paramsObj, getReport) => {
  // Parameters
  let root;
  let reportsetParams;
  if (data.reportset) {
    root = data.reportset.report[0];
    reportsetParams = {
      title: data.reportset.$.title,
      id: data.reportset.parameters[0].$.id,
      field: data.reportset.parameters[0].reportset_id[0].$.field,
      value: data.reportset.parameters[0].reportset_id[0].$.value,
      name: data.reportset.parameters[0].reportset_id[0].$.name,
    };
  } else if (data.report) {
    root = data.report[0];
  } else {
    root = data;
  }
  const params = root.parameters[0];
  // common parameters for all requests
  const parameters = {
    id: params.$.id,
    date_begin: params.date_begin[0].$.value,
    product_id: params.product_id[0].$.value,
    display_header: params.display_header[0].$.value,
    timeframe_id: params.timeframe_id ? params.timeframe_id[0].$.value : null,
    display_table: params.display_table[0].$.value,
    report_id: params.report_id[0].$.value,
  };
  // filter logic
  if (params.list) {
    params.list.forEach((l) => {
      if (l.filter_id) {
        parameters.filter_id = l.filter_id;
      }
      if (l.filterlist_id) {
        parameters.filterlist_id = l.filterlist_id;
      }
    });
  } else if (params.filter_id) {
    parameters.filter_id = params.filter_id;
  } else {
    parameters.filter_id = null;
  }
  // conditional parameters
  if (params.display_chart) {
    parameters.display_chart = params.display_chart[0].$.value;
  }
  if (params.dashboard_enabled) {
    parameters.dashboard_enabled = params.dashboard_enabled[0].$.value;
  }
  if (params.chart_id) {
    parameters.chart_id = params.chart_id[0].$.value;
  }
  if (params.date_end) {
    parameters.date_end = params.date_end[0].$.value;
  }
  // Header
  const head = root.header[0];
  // common header
  const header = {
    id: head.$.id,
    parameters: head.$.parameters,
    option: head.data[0].option.map((op) =>
      op.$.value ? { type: op.$.type, value: op.$.value } : { type: op.$.type }
    ),
  };
  // conditional header
  if (head.data[0].listitems) {
    header.listItems = head.data[0].listitems.map((li) =>
      li.item.map((l) => l.$.value)
    );
  }
  // Chart
  let chart;
  if (root.fusionchart) {
    chart = fusionChartToBillboard(root.fusionchart);
  }

  // Table
  let table = null;
  if (root.table) {
    if (root.table.length > 1) {
      table = [];
      root.table.forEach((tab) => {
        table.push(
          tableToReactTable(tab, paramsObj, getReport, parameters.report_id)
        );
      });
    } else {
      table = tableToReactTable(
        root.table[0],
        paramsObj,
        getReport,
        parameters.report_id
      );
    }
  }

  return {
    reportId: Math.random().toString(36).slice(-5),
    type: "report",
    name: data.reportset ? data.reportset.$.title : "dummy",
    header,
    parameters,
    parametersReportset: reportsetParams,
    chart,
    table,
  };
};

const checkNotDate = (el) => {
  return Number(isNaN(Number(el.substr(0, 8)))) || el.length < 8;
};

// return billboard.js data object (react-billboardjs)
const fusionChartToBillboard = (fusionArray) => {
  let mergeObject = {};
  let columns = [];
  // if there is a categories field, get categories from here.
  if (fusionArray[0].data[0].chart[0].categories) {
    // how do we detect category type?? check the length of the data, and if the first 8 values are numbers
    let check = checkNotDate(
      fusionArray[0].data[0].chart[0].categories[0].category[0].$.label
    );
    if (check) {
      const categoryColumn = fusionArray[0].data[0].chart[0].categories[0].category.map(
        (ca) => ca.$.label
      );
      categoryColumn.unshift("Cat");
      columns.push(categoryColumn);
      mergeObject = { data: { x: "Cat" }, axis: { x: { type: "category" } } };
    } else {
      const categoryColumn = fusionArray[0].data[0].chart[0].categories[0].category.map(
        (ca) => metrixDateToJsDate(ca.$.label)
      );
      categoryColumn.unshift("Date");
      columns.push(categoryColumn);
      mergeObject = {
        data: { x: "Date" },
        axis: { x: { type: "timeseries", format: "%Y-%m-%d" } },
      };
    }
  }

  // sets type
  const chartType = fusionArray[0].$.chart_id;
  const availableTypes = fusionArray[0].$.valid_ids.split(",");

  if (
    fusionArray[0].data[0].chart[0].set ||
    fusionArray[0].data[0].chart[0].dataset
  ) {
    // multiple date = <chart><dataset><set><set><set></dataset></chart>
    // single date = <chart><set></chart>

    // If there's a dataset, we alread have the category columns from above
    if (fusionArray[0].data[0].chart[0].dataset) {
      fusionArray[0].data[0].chart[0].dataset.forEach((ds) => {
        let column = ds.set.map((st) => Number(st.$.value));
        column.unshift(ds.$.seriesName);
        columns.push(column);
      });
    } else {
      // If there are only sets, it means category info is tied into the set itself

      // Should be running the date check here, not on the actual data
      // how do we detect category type?? check the length of the data, and if the first 8 values are numbers
      let checkFirst = checkNotDate(
        fusionArray[0].data[0].chart[0].set[0].$.toolText
      );
      if (checkFirst) {
        const categoryColumn = fusionArray[0].data[0].chart[0].set.map(
          (ca) => ca.$.toolText
        );
        categoryColumn.unshift("Cat");
        columns.push(categoryColumn);
        mergeObject = { data: { x: "Cat" }, axis: { x: { type: "category" } } };
      } else {
        const categoryColumn = fusionArray[0].data[0].chart[0].set.map((ca) =>
          metrixDateToJsDate(ca.$.toolText)
        );
        categoryColumn.unshift("Date");
        columns.push(categoryColumn);
        mergeObject = {
          data: { x: "Date" },
          axis: { x: { type: "timeseries", format: "%Y-%m-%d" } },
        };
      }
      let column;
      column = fusionArray[0].data[0].chart[0].set.map((st) =>
        Number(st.$.value)
      );
      column.unshift(
        fusionArray[0].data[0].chart[0].$.seriesName
          ? fusionArray[0].data[0].chart[0].$.seriesName
          : "data"
      );
      columns.push(column);
    }
    const chartObject = merge(
      {
        data: {
          columns: columns,
        },
        availableTypes,
        type: chartType,
      },
      mergeObject
    );
    return chartObject;
  } else {
    return "EMPTY_CHART";
  }
};

export default mapReportResponse;

// return react-table data format
// paramsObj to be used for drilldown reports only
const tableToReactTable = (table, paramsObj, getReport, reportId) => {
  // TEMPORARILY ISOLATE NON-SESSION PATH TABLES
  if (reportId === "si_pp_se") {
    // Formatted specifically for SessionPathTable
    if (table.data[0].row) {
      let columns = table.data[0].head[0].cell.map((cl, i) => {
        return {
          rawHeader: cl.$.value,
          header: (
            <th colSpan={cl.$.colspan ? Number(cl.$.colspan) : 1}>
              {cl.$.value ? cl.$.value.toUpperCase() : ""}
            </th>
          ),
          width: Number(cl.$.width),
        };
      });
      let rows = [];
      table.data[0].row.forEach((rw, index) => {
        let row = [];
        rw.cell.forEach((cl, i) => {
          if (cl.$.type === "submit") {
            row.push(
              <td
                style={{ width: cl.$.width ? `${cl.$.width}%` : null }}
                colSpan={cl.$.colspan ? Number(cl.$.colspan) : 1}
              >
                <Url value={cl.$.value} />
              </td>
            );
          } else {
            row.push(
              <td
                style={{
                  width: cl.$.width ? `${cl.$.width}%` : null,
                  backgroundColor: cl.$.bgcolor
                    ? "var(--color-primary)"
                    : "white",
                  color: cl.$.bgcolor ? "white" : "black",
                  fontWeight:
                    index === table.data[0].row.length - 1 ? 700 : 300,
                }}
                colSpan={cl.$.colspan ? Number(cl.$.colspan) : 1}
              >
                {cl.$.value}
              </td>
            );
          }
        });

        rows.push(row);
      });
      let footerValues = null;
      if (table.data[0].foot) {
        footerValues = table.data[0].foot[0].cell.map((cl, i) => {
          return (
            <th colSpan={cl.$.colspan ? Number(cl.$.colspan) : 1}>
              {cl.$.value}
            </th>
          );
        });
      }
      return {
        columns: columns,
        data: rows,
        footer: footerValues,
      };
    } else {
      return "EMPTY_TABLE";
    }
  } else {
    // All other report types
    if (table.data[0].row) {
      let columns = table.data[0].head[0].cell.map((cl, i) => {
        // DANGER: Hardcoded values will only work for English and Japanese
        if (cl.$.value === "Drilldown Report" || cl.$.value === "詳細") {
          return {
            rawHeader: cl.$.value,
            columnType: table.data[0].row[0].cell[i].$.type,
            Header: () => (
              <span
                title={cl.$.value}
                style={{
                  display: "block",
                  fontWeight: 700,
                }}
              >
                {cl.$.value.toUpperCase()}
              </span>
            ),
            accessor: `col${i}`,
            width: Number(cl.$.width),
            disableSortBy: !(cl.$.sortable && cl.$.sortable === "True"),
            Cell: (row) => (
              <div>
                <DrilldownButton
                  value={row.value}
                  originalParams={paramsObj}
                  getReport={getReport}
                />
              </div>
            ),
          };
        } else {
          return {
            rawHeader: cl.$.value,
            columnType: table.data[0].row[0].cell[i].$.type,
            Header: () => {
              switch (table.data[0].row[0].cell[i].$.type) {
                case "int":
                case "float":
                  return (
                    <span
                      title={cl.$.value}
                      style={{
                        textAlign: "right",
                        width: "100%",
                        display: "block",
                        fontWeight: 700,
                      }}
                    >
                      {cl.$.value.toUpperCase()}
                    </span>
                  );
                default:
                  return (
                    <span
                      title={cl.$.value}
                      style={{
                        display: "block",
                        fontWeight: 700,
                      }}
                    >
                      {cl.$.value.toUpperCase()}
                    </span>
                  );
              }
            },
            accessor: `col${i}`,
            width: Number(cl.$.width),
            disableSortBy: !(cl.$.sortable && cl.$.sortable === "True"),
            isSortedd: !!cl.$.sort,
            isSorteddDesc: cl.$.sort === "desc",
            Cell: (row) => {
              // page details specific case
              if (
                (reportId === "si_pg_dt" || reportId === "si_pg_dt_dn") &&
                i === 0
              ) {
                return <PageDetailUrl value={row.value} checkable />;
                // page path unique case
              } else if (reportId === "si_pp_pg") {
                if (i >= 4) {
                  return <Url value={row.value} noWrap padding={"0.5rem 0"} />;
                } else {
                  switch (table.data[0].row[0].cell[i].$.type) {
                    case "int":
                      return (
                        <div>
                          <span
                            title={row.value}
                            style={{
                              textAlign: "right",
                              width: "100%",
                              display: "block",
                            }}
                          >
                            {numberWithCommas(row.value)}
                          </span>
                        </div>
                      );

                    case "float":
                      return (
                        <div>
                          <span
                            title={row.value}
                            style={{
                              textAlign: "right",
                              width: "100%",
                              display: "block",
                            }}
                          >
                            {numberWithCommas(row.value.toFixed(2))}
                          </span>
                        </div>
                      );
                    default:
                      return (
                        <div>
                          <span title={row.value}>{row.value}</span>
                        </div>
                      );
                  }
                }
              } else {
                switch (table.data[0].row[0].cell[i].$.type) {
                  case "int":
                    return (
                      <div>
                        <span
                          title={row.value}
                          style={{
                            textAlign: "right",
                            width: "100%",
                            display: "block",
                          }}
                        >
                          {numberWithCommas(row.value)}
                        </span>
                      </div>
                    );

                  case "float":
                    return (
                      <div>
                        <span
                          title={row.value}
                          style={{
                            textAlign: "right",
                            width: "100%",
                            display: "block",
                          }}
                        >
                          {numberWithCommas(row.value.toFixed(2))}
                        </span>
                      </div>
                    );

                  case "submit":
                    return <PageDetailUrl value={row.value} />;

                  default:
                    return (
                      <div>
                        <span title={row.value}>{row.value}</span>
                      </div>
                    );
                }
              }
            },
          };
        }
      });
      if (table.data[0].foot) {
        let footerValues = table.data[0].foot[0].cell.map((cl, i) => {
          return {
            rawFooter: cl.$.value,
            Footer: () => {
              switch (table.data[0].row[0].cell[i].$.type) {
                case "int":
                case "float":
                  return (
                    <span
                      style={{
                        textAlign: "right",
                        width: "100%",
                        display: "block",
                        fontWeight: 700,
                      }}
                    >
                      {cl.$.value.toUpperCase()}
                    </span>
                  );
                default:
                  return (
                    <span
                      style={{
                        display: "block",
                        fontWeight: 700,
                      }}
                    >
                      {cl.$.value.toUpperCase()}
                    </span>
                  );
              }
            },
          };
        });
        columns = columns.map((col, i) =>
          Object.assign({}, col, footerValues[i])
        );
      }
      let rows = [];
      table.data[0].row.forEach((rw, i) => {
        let row = {};
        rw.cell.forEach((cl, i) => {
          if (cl.$.type == "drilldown") {
            // value for dropdown becomes the options
            row = { ...row, [`col${i}`]: cl.option };
          } else if (cl.$.type === "int") {
            row = { ...row, [`col${i}`]: Number(cl.$.rawvalue) };
          } else if (cl.$.type === "float") {
            row = {
              ...row,
              [`col${i}`]: Number(cl.$.rawvalue),
            };
          } else {
            row = { ...row, [`col${i}`]: cl.$.value };
          }
        });
     	if(rw.$.type !== 'remainder'){
          rows.push(row);
	}
      });
      return {
        columns: columns,
        data: rows,
      };
    } else {
      return "EMPTY_TABLE";
    }
  }
};

// Components that need to be passed via the report

const DrilldownButton = ({ value, originalParams, getReport }) => {
  const { openModal } = useContext(ModalContext);
  const { optionalFields } = useContext(OptionalFieldsContext);
  return (
    <span
      onClick={() =>
        openModal(
          <DrilldownReport
            drilldownOptions={value}
            parentOptions={originalParams}
            getReport={getReport}
            optionalFields={optionalFields}
          />,
          "Drilldown Report"
        )
      }
      style={{ cursor: "pointer" }}
    >
      <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: "0.5rem" }} />
      Select Report
    </span>
  );
};

const Url = ({ value, padding, noWrap }) => {
  const { addNotification } = useContext(NotificationsContext);
  return (
    <div style={{ display: "flex", padding: padding ? padding : "unset" }}>
      {value && (
        <FontAwesomeIcon
          icon={faCopy}
          style={{ cursor: "pointer", marginRight: "1rem" }}
          onClick={() =>
            navigator.clipboard
              .writeText(value)
              .then(() =>
                addNotification("notification", "Copied to Clipboard")
              )
              .catch((err) => addNotification("error", "Cannot copy"))
          }
        />
      )}
      <a
        style={{
          color: "var(--color-primary)",
          whiteSpace: noWrap ? "nowrap" : "unset",
        }}
        href={"https://" + value}
        target={"_blank"}
        rel="noreferrer"
        title={value}
      >
        {value}
      </a>
    </div>
  );
};

const PageDetailUrl = ({ value, padding, noWrap, checkable }) => {
  const { addNotification } = useContext(NotificationsContext);
  const { pageDetailChecks, handlePageDetailChecks } = useContext(
    CheckboxContext
  );
  return (
    <div style={{ display: "flex", padding: padding ? padding : "unset" }}>
      <FontAwesomeIcon
        icon={faCopy}
        style={{ cursor: "pointer", marginRight: "1rem" }}
        onClick={() =>
          navigator.clipboard
            .writeText(value)
            .then(() => addNotification("notification", "Copied to Clipboard"))
            .catch((err) => addNotification("error", "Cannot copy"))
        }
      />
      {checkable && (
        <input
          name="isGoing"
          type="checkbox"
          checked={!!pageDetailChecks.find((v) => v === value)}
          onChange={() => handlePageDetailChecks(value)}
          style={{ marginRight: "1rem" }}
        />
      )}
      <a
        style={{
          color: "var(--color-primary)",
          whiteSpace: noWrap ? "nowrap" : "unset",
        }}
        href={"https://" + value}
        target={"_blank"}
        rel="noreferrer"
        title={value}
      >
        {value}
      </a>
    </div>
  );
};
