import styled from "styled-components";

const Checkbox = ({ label, disabled, ...rest }) => {
  return (
    <CheckboxLabel>
      <span>{label.toUpperCase()}</span>
      <input type="checkbox" {...rest}></input>
      <span></span>
    </CheckboxLabel>
  );
};

export default Checkbox;

const CheckboxLabel = styled.label`
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  margin: var(--margin-input-default);
  padding: var(--padding-input-default);
  font-size: 16px;
  user-select: none;
  > span:first-child {
    font-weight: 700;
    /* width: 5rem; */
    margin-right: 1rem;
    color: var(--color-label-primary);
    cursor: pointer;
  }
  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  > span:nth-child(3) {
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid var(--color-button-primary);
    background-color: #eee;
  }
  > input:checked ~ span {
    background-color: var(--color-button-primary);
  }
`;
