import { useState, useRef, useLayoutEffect, useContext } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { config, useTransition, useSpring, animated } from "react-spring";

import { useHeight } from "tools/useHeight";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ModalContext, ModalInternalContext } from "context/Modal";

const Modal = () => {
  const [heightOn, setHeightOn] = useState(false);
  const [sizingRef, contentHeight] = useHeight({ on: heightOn });
  const uiReady = useRef(false);
  const { openModal, closeModal } = useContext(ModalContext);
  const { isOpen, modalContent, modalTitle, modalSubtitle } = useContext(
    ModalInternalContext
  );

  const activateRef = (ref) => {
    sizingRef.current = ref;
    if (!heightOn) {
      setHeightOn(true);
    }
  };

  const modalTransition = useTransition(!!isOpen, {
    config: isOpen ? { ...config.stiff } : { duration: 150 },
    from: { opacity: 0, transform: `translate3d(0px, -10px, 0px)` },
    enter: { opacity: 1, transform: `translate3d(0px, 0px, 0px)` },
    leave: { opacity: 0, transform: `translate3d(0px, 10px, 0px)` },
  });

  const heightStyles = useSpring({
    immediate: !uiReady.current,
    config: { ...config.stiff },
    from: { height: 0 },
    to: { height: contentHeight },
    onRest: () => (uiReady.current = true),
  });

  useLayoutEffect(() => {
    if (!isOpen) {
      setHeightOn(false);
    }
  }, [isOpen]);

  const overlayClick = (e) => {
    e.stopPropagation();
    closeModal();
  };
  const containerClick = (e) => {
    e.stopPropagation();
  };
  /* React spring currently broken, so bypassing for now
  return ReactDOM.createPortal(
    modalTransition(
      (styles, isOpen) =>
        isOpen && (
          <ModalOverlay
            style={{ opacity: styles.opacity }}
            onClick={overlayClick}
          >
            <ModalContainer style={{ ...styles }} onClick={containerClick}>
              <div>
                <span>
                  {modalTitle}
                  {modalSubtitle && "  |  "}
                  <span>{modalSubtitle && modalSubtitle}</span>
                </span>
                <CloseButton onClick={closeModal}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    color={"#aaa"}
                    style={{ cursor: "pointer" }}
                  />
                </CloseButton>
              </div>
              {modalContent}
            </ModalContainer>
          </ModalOverlay>
        )
    ),
    document.querySelector("#modal-root")
  );
  */

  return ReactDOM.createPortal(
    isOpen && (
      <ModalOverlay onClick={overlayClick}>
        <ModalContainer onClick={containerClick}>
          <div>
            <span>
              {modalTitle}
              {modalSubtitle && "  |  "}
              <span>{modalSubtitle && modalSubtitle}</span>
            </span>
            <CloseButton onClick={closeModal}>
              <FontAwesomeIcon
                icon={faTimes}
                color={"#aaa"}
                style={{ cursor: "pointer" }}
              />
            </CloseButton>
          </div>
          {modalContent}
        </ModalContainer>
      </ModalOverlay>
    ),
    document.querySelector("#modal-root")
  );
};

export default Modal;

const CloseButton = styled.i`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: grid;
  place-items: center;

  &:hover {
    background-color: var(--color-hover-opacity);
  }
`;

const ModalContainer = styled(animated.div)`
  max-width: 80vw;
  padding: 2rem;
  min-width: 150px;
  cursor: default;
  background-color: var(--color-paper);
  display: flex;
  flex-direction: column;

  box-shadow: var(--box-shadow-3);
  > div:first-child {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    > span {
      font-size: var(--font-size-3);
      font-weight: 700;
      > span {
        color: #aaa;
        font-weight: 300;
      }
    }
  }
`;

const ModalOverlay = styled(animated.div)`
  position: fixed;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  z-index: var(--z-index-modal);
`;
