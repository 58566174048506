const mapReportTypesResponse = (data) => {
  let reportArray = [];
  data.reports.section.forEach((el) => {
    const name = el.$.name;
    let subReportArray = [];
    el.report.forEach((r) => {
      const timeframe = r.timeframe.map((rt) => ({
        id: rt.$.id,
        name: rt.$.name,
        ntype: rt.$.ntype,
      }));
      const required = r.required.map((rq) => ({
        id: rq.$.id,
        select: rq.$.select,
      }));
      const optional = r.optional.map((op) => ({
        id: op.$.id,
        select: op.$.select,
      }));
      let chart;
      if (r.chart) {
        chart = r.chart.map((ch) => ({
          id: ch.$.id,
        }));
      }
      subReportArray.push({
        timeframe,
        required,
        optional,
        chart: chart ? chart : null,
        id: r.$.id,
        name: r.$.name,
        ntype: r.$.ntype,
      });
    });
    reportArray.push({
      name,
      report: subReportArray,
    });
  });
  return reportArray;
};

export default mapReportTypesResponse;
