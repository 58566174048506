import styled from "styled-components";
import logo from "../../logo2.png";

import { useContext } from "react";

import { LocalizationContext } from "context/Localization";
import { ModalContext } from "context/Modal";

import Preferences from "components/Modal/components/Preferences";
import EmailReports from "components/Modal/components/EmailReports";
import ExportFilterDefs from "components/Modal/components/ExportFilterDefs";

import {
  faLanguage,
  faMailBulk,
  faCog,
  faQuestion,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";

import Button from "components/Button";

const Header = ({ logout, filters, filterlist, loadFilterlist, username }) => {
  const { mapi18n } = useContext(LocalizationContext);
  const { openModal } = useContext(ModalContext);

  const openHelp = async () => {
    window.open(process.env.REACT_APP_API_ENDPOINT + "help/?product_id=si");
  };

  const reloadFilterlist = async () => {
    try {
      await loadFilterlist();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <HeaderContainer>
      <div>
        <Logo src={logo} alt="bluemetrix-logo" />
      </div>
      <div>
        <div style={{ padding: "8px 16px" }}>
          <span style={{ fontWeight: 700, fontSize: "var(--font-size-6)" }}>
            {"USER: "}
          </span>
          <span>{username}</span>
        </div>
        <span>|</span>
        <Button
          color={"black"}
          tertiary
          icon={faQuestion}
          onClick={openHelp}
          label={mapi18n("rep_hnt_help")}
        />
        <span>|</span>
        <Button
          tertiary
          color={"black"}
          icon={faPaperclip}
          onClick={() =>
            openModal(
              <ExportFilterDefs username={username} />,
              mapi18n("mnu_btn_fl")
            )
          }
          label={mapi18n("mnu_btn_fl")}
        />
        <span>|</span>
        <Button
          tertiary
          icon={faMailBulk}
          label={mapi18n("prf_lbl_emlexp")}
          onClick={() =>
            openModal(
              <EmailReports />,
              mapi18n("prf_lbl_emlexp"),
              mapi18n("prf_lbl_edeml")
            )
          }
          color={"black"}
        />
        <span>|</span>

        <Button
          tertiary
          icon={faCog}
          label={mapi18n("mnu_btn_pref")}
          onClick={() =>
            openModal(
              <Preferences
                filters={filters}
                filterlist={filterlist}
                logout={logout}
                reloadFilterlist={reloadFilterlist}
              />,
              mapi18n("mnu_btn_pref")
            )
          }
          color={"black"}
        />
        <Button
          primary
          label={mapi18n("mnu_btn_lo")}
          style={{ marginLeft: "0.5rem" }}
          onClick={logout}
        />
      </div>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.header`
  width: 100%;
  height: 70px;
  background-color: var(--color-paper);
  box-shadow: var(--box-shadow-1);
  z-index: var(--z-index-header);
  display: flex;
  justify-content: space-between;
  padding: 0rem 2rem;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    height: 100%;
    > button {
      margin: 0;
    }
  }
`;

const Logo = styled.img`
  max-height: 40px;
`;
