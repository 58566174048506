import { useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";

import { transpose } from "tools/misc";

import Button from "components/Button";
import Loading from "components/Loading";

import {
  faChartBar,
  faChartLine,
  faChartPie,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";

import BillboardChart from "react-billboardjs";
import "react-billboardjs/lib/billboard.css";

const Chart = ({ chart, mapi18n, printing }) => {
  const [type, setType] = useState(chart.type);
  const [axisXType, setAxisXType] = useState(chart.axis.x.type);
  const [charrt, setCharrt] = useState(null);
  const [lock, setLock] = useState(false);
  useEffect(() => {
    // fix dodgy chart change
    // remove chart
    // wait a second
    // reinstante chart with new chart
    if (charrt) {
      if (!lock) {
        setLock(true);
        setCharrt(null);
      }

      const timer = setTimeout(() => {
        let newChart = mapFusionChartType(chart, type, axisXType);
        setCharrt(newChart);
        setLock(false);
      }, 800);

      return () => clearTimeout(timer);
    }
  }, [type]);

  useEffect(() => {
    const timer = setTimeout(() => {
      let newChart = mapFusionChartType(chart, type, axisXType);
      setCharrt(newChart);
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container printing={printing}>
      <ButtonRow>
        {chart.availableTypes.map((t, i) => (
          <Button
            secondary
            key={i}
            title={mapi18n(chartTypeMap[t].title)}
            icon={chartTypeMap[t].icon}
            rotate={chartTypeMap[t].rotate}
            flip={chartTypeMap[t].flip}
            selected={t === type}
            onClick={() => setType(t)}
          />
        ))}
      </ButtonRow>
      {charrt ? (
        <div>
          <BillboardChart
            unloadBeforeLoad
            className="billboardClass"
            data={charrt.data}
            legend={charrt.legend}
            axis={charrt.axis ? charrt.axis : { rotation: "false" }}
            zoom={type !== "pie" ? { enabled: { type: "drag" } } : null}
          />
        </div>
      ) : (
        <div>
          <Loading />
        </div>
      )}
    </Container>
  );
};

export default Chart;

const Container = styled.div`
  background-color: var(--color-bluemetrix-blue-2);

  padding: 1rem 2rem;
  > div:nth-child(2) {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }
  @media print {
    > div:nth-child(2) {
      height: 60%;
      display: block;
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const chartTypeMap = {
  p2d: { title: "rep_hnt_pc", icon: faChartPie },
  mp2d: { title: "rep_hnt_pc", icon: faChartPie },
  l2d: { title: "rep_hnt_lc", icon: faChartLine },
  ml2d: { title: "rep_hnt_lc", icon: faChartLine },
  b2d: {
    title: "rep_hnt_bc",
    icon: faChartBar,
    rotate: 90,
    flip: true,
  },
  mb2d: {
    title: "rep_hnt_bc",
    icon: faChartBar,
    rotate: 90,
    flip: true,
  },
  c2d: { title: "rep_hnt_cc", icon: faChartBar },
  mc2d: { title: "rep_hnt_cc", icon: faChartBar },
  sc2d: { title: "rep_hnt_stck", icon: faLayerGroup },
};

const mapFusionChartType = (chart, type, axisType) => {
  switch (type) {
    case "mp2d":
      return {
        data: {
          ...chart.data,
          type: "pie",
        },
        // hide legend if there will be too much text in it
        legend: {
          show: chart.data.columns[0].join("").length < 200,
        },
      };
    case "p2d":
      return {
        data: {
          ...chart.data,
          type: "pie",
          columns: transpose(chart.data.columns),
        },
        // hide legend if there will be too much text in it
        legend: {
          show: chart.data.columns[0].join("").length < 200,
        },
      };

    case "mc2d":
    case "c2d":
      if (axisType === "timeseries") {
        return {
          data: {
            ...chart.data,
            type: "bar",
          },
          axis: {
            rotated: false,
            x: {
              type: axisType,
              tick: {
                rotate: 75,
                multiline: true,
                format: "%Y-%m-%d",
              },
            },
          },
        };
      } else {
        return {
          data: {
            ...chart.data,
            type: "bar",
          },
          axis: {
            rotated: false,
            x: {
              type: axisType,
              tick: {
                rotate: 75,
                multiline: true,
              },
            },
          },
        };
      }

    case "ml2d":
    case "l2d":
      if (axisType === "timeseries") {
        return {
          data: {
            ...chart.data,
            type: "line",
          },
          axis: {
            x: {
              type: axisType,
              tick: {
                rotate: 75,
                format: "%Y-%m-%d",
              },
            },
          },
        };
      } else {
        return {
          data: {
            ...chart.data,
            type: "line",
          },
          axis: {
            x: {
              tick: {
                rotate: 75,
              },
              type: axisType,
            },
          },
        };
      }

    case "mb2d":
    case "b2d":
      if (axisType === "timeseries") {
        return {
          data: {
            ...chart.data,
            type: "bar",
          },
          axis: {
            rotated: true,
            x: {
              type: axisType,
              tick: {
                format: "%Y-%m-%d",
                rotate: 75,
                multiline: false,
                culling: {
                  max: 24,
                },
              },
            },
          },
        };
      } else {
        return {
          data: {
            ...chart.data,
            type: "bar",
          },
          axis: {
            rotated: true,
            x: {
              type: axisType,
              tick: {
                rotate: 75,
                multiline: false,
                culling: {
                  max: 24,
                },
              },
            },
          },
        };
      }

    case "sc2d":
      if (chart.axis.x.type === "timeseries") {
        return {
          data: {
            ...chart.data,
            type: "bar",
            groups: [chart.data.columns.map((arr) => arr[0])],
          },
          axis: {
            rotated: false,
            x: {
              type: axisType,

              tick: {
                rotate: 75,
                multiline: true,
                format: "%Y-%m-%d",
              },
            },
          },
        };
      } else {
        return {
          data: {
            ...chart.data,
            type: "bar",
            groups: [chart.data.columns.map((arr) => arr[0]).slice(1)],
          },
          axis: {
            rotated: false,
            x: {
              type: axisType,

              tick: {
                rotate: 75,
                multiline: true,
              },
            },
          },
        };
      }

    default:
      return {
        data: {
          ...chart.data,
          type: "bar",
        },
      };
  }
};
