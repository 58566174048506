const mapFilterResponse = (data) => {
  const filterMapping = data.filters.filter.map((fi) => ({
    id: fi.$.id,
    name: fi.$.name,
    databaseid: fi.$.databaseid,
    ntype: fi.$.ntype,
  }));
  return {
    name: data.filters.$.name,
    ntype: data.filters.$.ntype,
    link: data.filters.$.link,
    filter: filterMapping,
  };
};

export default mapFilterResponse;
