import styled from "styled-components";

const SessionPathTable = ({ table, visible, collapse }) => {
  return (
    <Container
      data-collapse-table={collapse ? "true" : "false"}
      style={{ display: visible ? "block" : "none" }}
    >
      <StyledTable>
        <THead>
          <tr>{table.columns.map((cl) => cl.header)}</tr>
        </THead>
        <TBody>
          {table.data.map((rw) => (
            <tr>{rw.map((cl) => cl)}</tr>
          ))}
        </TBody>
        <TFoot>{table.footer && <tr>{table.footer.map((cl) => cl)}</tr>}</TFoot>
      </StyledTable>
    </Container>
  );
};

export default SessionPathTable;

const Container = styled.div``;

const StyledTable = styled.table`
  width: 100%;
  margin: 2rem 0;
  border-spacing: 0;
`;

const TFoot = styled.tfoot`
  > tr {
    background-color: var(--color-dashboard);
    > th {
      text-align: left;
      font-size: var(--font-size-7);
      padding: 0.25rem 0.5rem;
      overflow: hidden;
      color: white;
    }
  }
`;

const THead = styled.thead`
  > tr {
    > th {
      text-align: left;
      font-size: var(--font-size-6);
      padding: 0.25rem 0.5rem;
      overflow: hidden;
    }
  }
`;

const TBody = styled.tbody`
  > tr {
    > td {
      font-size: var(--font-size-7);
      padding: 0.25rem 0.5rem;
      overflow: hidden;
    }
  }
  > tr:nth-child(odd) {
    background-color: var(--color-paper-rough);
    > td {
      font-size: var(--font-size-7);
      padding: 0.25rem 0.5rem;
    }
  }
`;
