import styled from "styled-components";
import { useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import { NotificationsInternalContext } from "context/Notifications";

const NOTIFICATION_LIFETIME = 3000;

const Notification = ({ type, message, id }) => {
  const { removeNotification } = useContext(NotificationsInternalContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      removeNotification(id);
    }, NOTIFICATION_LIFETIME);
    return () => clearTimeout(timer);
  }, []);

  return (
    <NotificationContainer type={type} key={id}>
      <div>
        <FontAwesomeIcon
          icon={
            {
              notification: faInfoCircle,
              warning: faExclamationTriangle,
              error: faExclamationCircle,
            }[type]
          }
          color="var(--color-notification-contrast)"
        ></FontAwesomeIcon>
        <span>{message}</span>
      </div>
    </NotificationContainer>
  );
};

export default Notification;

const NotificationContainer = styled.div`
  width: 100%;
  ${(p) =>
    p.type === "notification" && "background-color: var(--color-primary);"}
  ${(p) =>
    p.type === "warning" && "background-color: var(--color-warning);"}
    ${(
    p
  ) =>
    p.type === "error" &&
    "background-color: var(--color-error);"}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-notification-default);
  box-shadow: var(--box-shadow-3);
  margin: var(--margin-notification-default);

  > div {
    *:first-child {
      margin: var(--margin-button-icon-default);
    }
    > span {
      color: var(--color-notification-contrast);
    }
  }
`;
