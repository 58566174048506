import { useState, useContext, useEffect } from "react";
import styled from "styled-components";

// react select onChange returns array for multi, object for single
import Select from "react-select";

import Input from "components/Input";
import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Radio from "components/Radio";
import SortableList from "components/SortableList";

import { ModalContext } from "context/Modal";
import { NotificationsContext } from "context/Notifications";
import { LocalizationContext } from "context/Localization";

import { metrixDateToJsDate, formatDate, binarify } from "tools/misc";

// Convert filterids (and filterlist ids) into API friendly form
const formatFilters = (filterIds, selectType) => {
  console.log(filterIds);
  if (selectType == "single") {
    return filterIds[0];
  } else {
    if(typeof filterIds === "string") {
      return filterIds;
    } else {
      return filterIds.join();
    }
    
  }
};

const NewReport = ({
  report,
  filters,
  filterlist,
  getReport,
  setCurrentReport,
  reportsLength,
  reportRequest,
  dateLimits,
}) => {
  const { closeModal } = useContext(ModalContext);
  const { addNotification } = useContext(NotificationsContext);
  const { mapi18n } = useContext(LocalizationContext);

  // Date data
  const [timeframeConfig, setTimeframeConfig] = useState();
  const [timeframeOptions, setTimeframeOptions] = useState();
  // timeframe_id :
  const [timeframe, setTimeframe] = useState(
    reportRequest ? reportRequest.timeframe_id : null
  );
  const [waiting, setWaiting] = useState(null);

  // Date Config
  const [dateConfig, setDateConfig] = useState();
  // date_begin : Needs mapping with metrixDateToJsDate
  const [startDate, setStartDate] = useState(
    reportRequest ? metrixDateToJsDate(reportRequest.date_begin) : null
  );
  // date_end : Needs mapping with metrixDateToJsDate
  const [endDate, setEndDate] = useState(
    reportRequest ? metrixDateToJsDate(reportRequest.date_end) : null
  );

  const [searchString, setSearchString] = useState(
    reportRequest && reportRequest.search_string
      ? reportRequest.search_string
      : ""
  );

  // Filters
  const [filterConfig, setFilterConfig] = useState();
  const [filterOptions, setFilterOptions] = useState();
  const [filterListOptions, setFilterListOptions] = useState();
  // filter_id : split by comma
  const [filterIds, setFilterIds] = useState(
    reportRequest && reportRequest.filter_id
      ? typeof reportRequest.filter_id === "array"
        ? reportRequest.filter_id.split(",")
        : reportRequest.filter_id
      : null
  );
  // filterlist_id : split by comma
  const [filterListIds, setFilterListIds] = useState(
    reportRequest && reportRequest.filterlist_id
      ? typeof reportRequest.filterlist_id === "array"
        ? reportRequest.filterlist_id.split(",")
        : reportRequest.filterlist_id
      : null
  );

  const [filterRequired, setFilterRequired] = useState(false);

  const [displayModeOptions, _] = useState([
    { value: 0, label: mapi18n("pp_lbl_url") },
    { value: 1, label: mapi18n("pp_lbl_dsplname") },
  ]);
  const [displayMode, setDisplayMode] = useState(
    reportRequest && reportRequest.display_mode
      ? displayModeOptions.find((dm) => dm.value == reportRequest.display_mode)
      : displayModeOptions[0]
  );
  const [displayModeRequired, setDisplayModeRequired] = useState(false);

  // Page path specific state
  const [pagePath, setPagePath] = useState(false);
  const [pageDistribution, setPageDistribution] = useState(false);
  const [sessionPath, setSessionPath] = useState(false);

  const [entryKey, setEntryKey] = useState(
    reportRequest &&
      reportRequest.entry_url &&
      reportRequest.report_id !== "si_pp_se"
      ? decodeURIComponent(reportRequest.entry_url)
      : null
  );

  const [ekOptions, setEKOptions] = useState([
    { value: "entry", label: "entry" },
    { value: "key", label: "key" },
  ]);
  const [selectedEKOption, setSelectedEKOption] = useState(ekOptions[0]);
  const [endGoal, setEndGoal] = useState(
    reportRequest && reportRequest.end_url
      ? decodeURIComponent(reportRequest.end_url)
      : null
  );
  const [egOptions, setEGOptions] = useState([
    { value: "end", label: "end" },
    { value: "goal", label: "goal" },
  ]);
  const [selectedEGOption, setSelectedEGOption] = useState(egOptions[0]);
  // page dist options
  const [pdOptions, setPDOptions] = useState([
    { value: 8, label: mapi18n("pp_lbl_entryexitdist") },
    { value: 9, label: mapi18n("pp_lbl_keyexitdist") },
    { value: 10, label: mapi18n("pp_lbl_exitentrydist") },
    { value: 11, label: mapi18n("pp_lbl_goalentrydist") },
  ]);
  const [selectedPDOption, setSelectedPDOption] = useState(
    reportRequest && reportRequest.report_id === "si_pp_di"
      ? pdOptions.find((o) => o.value === reportRequest.page_mode)
      : pdOptions[0]
  );

  const [sessionPathSites, setSessionPathSites] = useState(
    reportRequest &&
      reportRequest.entry_url &&
      reportRequest.report_id === "si_pp_se"
      ? decodeURIComponent(reportRequest.entry_url)
          .split(":")
          .map((v) => ({ label: v, value: v }))
      : []
  );
  const [newSessionPath, setNewSessionPath] = useState(null);

  const mapReport = (report) => {
    // REQUIRED
    const requiredArray = report.required.map((rq) => rq.id);
    // Timeframe
    // if there is more than one timeframe, set config so it appears in ui
    // else
    // set value to "n" and leave value
    if (report.timeframe.length > 1) {
      setTimeframeConfig(report.timeframe);
      setTimeframeOptions(
        report.timeframe.map((tf) => ({ value: tf.id, label: tf.name }))
      );
    } else {
      setTimeframe("n");
    }

    // Date
    if (requiredArray.includes("date_begin")) {
      let dateConfiguration = ["date_begin"];
      if (requiredArray.includes("date_end")) {
        dateConfiguration.push("date_end");
      }
      setDateConfig(dateConfiguration);
    }
    // Filters
    if (requiredArray.includes("filter_id")) {
      setFilterOptions(
        filters.filter.map((fi) => ({ value: fi.id, label: fi.name }))
      );
      let filterConfiguration = [];
      let selectMode = report.required.find((rq) => rq.id === "filter_id")
        .select;
      filterConfiguration = [
        { id: "filter_id", select: selectMode, required: true },
      ];
      if (requiredArray.includes("filterlist_id")) {
        if (filterlist) {
          setFilterListOptions(
            filterlist.map((fi) => ({ value: fi.id, label: fi.name }))
          );
        } else {
          setFilterListOptions([]);
        }
        filterConfiguration.push({
          id: "filterlist_id",
          select: selectMode,
          required: true,
        });
      }
      setFilterConfig(filterConfiguration);
      setFilterRequired(true);
    }

    // Page path
    if (requiredArray.includes("display_mode")) {
      setDisplayModeRequired(true);
    }

    // specific report types
    // Page Path
    if (report.id === "si_pp_pg") {
      setPagePath(true);
    }

    if (report.id === "si_pp_di") {
      setPageDistribution(true);
    }

    if (report.id === "si_pp_se") {
      setSessionPath(true);
    }

    // OPTIONAL
    const optionalArray = report.optional.map((rq) => rq.id);
    if (optionalArray.includes("filter_id")) {
      setFilterOptions(
        filters.filter.map((fi) => ({ value: fi.id, label: fi.name }))
      );
      let filterConfiguration = [];
      let selectMode = report.optional.find((rq) => rq.id === "filter_id")
        .select;
      filterConfiguration = [
        { id: "filter_id", select: selectMode, required: false },
      ];
      if (optionalArray.includes("filterlist_id")) {
        if (filterlist) {
          setFilterListOptions(
            filterlist.map((fi) => ({ value: fi.id, label: fi.name }))
          );
        } else {
          setFilterListOptions([]);
        }
        filterConfiguration.push({
          id: "filterlist_id",
          select: selectMode,
          required: false,
        });
      }
      setFilterConfig(filterConfiguration);
    }
  };

  const handleFilterChange = (value) => {
    if (value) {
      if (filterConfig[0].select == "multi") {
        setFilterIds(value.map((val) => val.value));
      } else {
        setFilterIds(value.value);
      }
    } else {
      setFilterIds(null);
    }
  };

  const handleFilterListChange = (value) => {
    if (value) {
      if (filterConfig[1].select == "multi") {
        setFilterListIds(value.map((val) => val.value));
      } else {
        setFilterListIds(value.value);
      }
    } else {
      setFilterListIds(null);
    }
  };

  const handleTimeframeChange = (value) => {
    setTimeframe(value.value);
  };

  const handleAddSessionPathSite = () => {
    if (
      newSessionPath &&
      !sessionPathSites.map((sps) => sps.value).includes(newSessionPath)
    ) {
      setSessionPathSites((sps) => [
        ...sps,
        {
          value: newSessionPath,
          label: newSessionPath,
        },
      ]);
      setNewSessionPath("");
    }
  };

  /*
  REPORT_PP_ENTRYPAGE_EXIT 0 = 'entry' , Empty second field; end selected
REPORT_PP_KEYPAGE_EXIT 1 = 'key' Empty second input box 
REPORT_PP_ENTRYPAGE_EXITPAGE 2
REPORT_PP_ENTRYPAGE_GOALPAGE 3
REPORT_PP_KEYPAGE_EXITPAGE 4
REPORT_PP_KEYPAGE_GOALPAGE 5
REPORT_PP_EXITPAGE_REVERSEPATH 6 = 'entry' , first empty, 'end' second,
REPORT_PP_KEYPAGE_REVERSEPATH 7 = 'entry', first empty, 'goal' second

entrykey = first
endGoal = second
  */

  const mapPageMode = (value) => {
    switch (value) {
      case 0:
        setSelectedEKOption(ekOptions[0]);
        setSelectedEGOption(egOptions[0]);
        break;
      case 1:
        setSelectedEKOption(ekOptions[1]);
        setSelectedEGOption(egOptions[0]);
        break;
      case 2:
        setSelectedEKOption(ekOptions[0]);
        setSelectedEGOption(egOptions[0]);
        break;
      case 3:
        setSelectedEKOption(ekOptions[0]);
        setSelectedEGOption(egOptions[1]);
        break;
      case 4:
        setSelectedEKOption(ekOptions[1]);
        setSelectedEGOption(egOptions[0]);
        break;
      case 5:
        setSelectedEKOption(ekOptions[1]);
        setSelectedEGOption(egOptions[1]);
        break;
      case 6:
        setSelectedEKOption(ekOptions[0]);
        setSelectedEGOption(egOptions[0]);
        break;
      case 7:
        setSelectedEKOption(ekOptions[0]);
        setSelectedEGOption(egOptions[1]);
        break;
      case 14:
        setSelectedEKOption(ekOptions[0]);
        setSelectedEGOption(egOptions[0]);
        break;
      case 15:
        setSelectedEKOption(ekOptions[0]);
        setSelectedEGOption(egOptions[0]);
        break;
      case 16:
        setSelectedEKOption(ekOptions[1]);
        setSelectedEGOption(egOptions[0]);
        break;
      default:
        setSelectedEKOption(ekOptions[0]);
        setSelectedEGOption(egOptions[0]);
    }
  };
  useEffect(() => {
    if (reportRequest && reportRequest.page_mode) {
      mapPageMode(reportRequest.page_mode);
    }
  }, []);

  const pagePathMapping = () => {
    const bin = binarify(
      selectedEGOption.value === "goal",
      selectedEKOption.value === "key"
    );
    switch (bin) {
      case 0:
        // entry end
        if (!endGoal) {
          return 0;
        } else if (!entryKey) {
          return 6;
        } else {
          return 2;
        }

      case 1:
        // key end
        if (!endGoal) {
          return 1;
        } else if (!entryKey) {
          return 6;
        } else {
          return 4;
        }

      case 2:
        // entry goal
        if (!endGoal) {
          return 0;
        } else if (!entryKey) {
          return 7;
        } else {
          return 3;
        }
      case 3:
        // key goal
        if (!endGoal) {
          return 1;
        } else if (!entryKey) {
          return 7;
        } else {
          return 5;
        }

      default:
        throw new Error("err");
    }
  };

  const handleSubmit = async () => {
    try {
      setWaiting("submit");
      // 1. build object containing fields.
      let optionalFields = {};
      let submitObject = {
        report_id: report.id,
        product_id: "si",
      };
      // date
      if (dateConfig) {
        // todo: format date correctly for API
        if (dateConfig.length === 2) {
          if (startDate && endDate) {
            submitObject = {
              ...submitObject,
              date_begin: formatDate(startDate),
              date_end: formatDate(endDate),
            };
          } else {
            addNotification("warning", mapi18n("form_lbl_seldate"));
            setWaiting(null);
            return;
          }
        } else {
          if (startDate) {
            submitObject = {
              ...submitObject,
              date_begin: formatDate(startDate),
              date_end: formatDate(endDate),
            };
          } else {
            addNotification("warning", mapi18n("form_lbl_seldate"));
            setWaiting(null);
            return;
          }
        }
      }
      // filters
      if (filterConfig) {
        if (filterConfig.length === 2) {
          if (filterIds) {
            submitObject = {
              ...submitObject,
              filter_id: formatFilters(filterIds, filterConfig[0].select),
            };
          }
          if (filterListIds) {
            submitObject = {
              ...submitObject,
              filterlist_id: formatFilters(
                filterListIds,
                filterConfig[1].select
              ),
            };
          }
          if (!filterIds && !filterListIds && filterRequired) {
            addNotification("warning", mapi18n("gen_lbl_fl"));
            setWaiting(null);
            return;
          }
        } else {
          if (filterIds) {
            submitObject = {
              ...submitObject,
              filter_id: filterIds,
            };
          } else if (filterRequired) {
            addNotification("warning", mapi18n("gen_lbl_fl"));
            setWaiting(null);
            return;
          }
        }
      }

      // load search into request so interface knows it is possible to search on this entry
      if (report.optional.find((op) => op.id === "search_string")) {
        optionalFields = { ...optionalFields, search_string: null };
      }
      if (!timeframe) {
        addNotification("warning", mapi18n("gen_lbl_tf"));
        setWaiting(null);
        return;
      }
      if (pagePath) {
        submitObject = {
          ...submitObject,
          display_mode: displayMode.value,
          page_mode: pagePathMapping(),
          end_url: encodeURIComponent(endGoal),
          entry_url: encodeURIComponent(entryKey),
        };
      }
      if (pageDistribution) {
        submitObject = {
          ...submitObject,
          display_mode: displayMode.value,
          page_mode: selectedPDOption.value,
          end_url: "",
          entry_url: entryKey,
        };
      }
      if (searchString) {
        submitObject = {
          ...submitObject,
          search_string: searchString,
        };
      }
      if (sessionPath) {
        submitObject = {
          ...submitObject,
          display_mode: displayMode.value,
          /*
          entry_url: 
            "san%2Da%2Ecom%2F:www%2Echumon%2Dsan%2Da%2Ecom%2Fspur%2F%2A:www%2Echumon%2Dsan%2Da%2Ecom%2Finquiry%2F%2A%2Fthanks%2Ehtml" /*encodeURIComponent(
            sessionPathSites.map((sps) => sps.value).join(":")
          ),*/
          entry_url: sessionPathSites
            .map((sps) => encodeURIComponent(sps.value))
            .join(":"),
          page_mode: selectedEKOption.value === "entry" ? 15 : 16,
        };
      }
      submitObject = {
        ...submitObject,
        timeframe_id: timeframe,
        reportset_id: "34394834734834",
      };
      await getReport(submitObject, null, optionalFields);
      closeModal();
    } catch (err) {
      addNotification("error", "An error occurred");
      console.error(err);
      setWaiting(null);
    }
  };

  useEffect(() => {
    mapReport(report);
  }, []);

  return (
    <Container>
      <Body>
        <LeftColumn>
          <DatePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            min={metrixDateToJsDate(dateLimits.start)}
            max={metrixDateToJsDate(dateLimits.end)}
          />
        </LeftColumn>
        <RightColumn>
          {filterConfig && (
            <FormItem>
              <span>
                {mapi18n("mnu_btn_fl")}
                {filterConfig[0].required && (
                  <span style={{ color: "red" }}> *</span>
                )}
              </span>

              <Select
                closeMenuOnSelect={!(filterConfig[0].select === "multi")}
                isMulti={filterConfig[0].select === "multi"}
                onChange={handleFilterChange}
                options={filterOptions}
                value={filterOptions.filter((op) =>
                  filterIds ? filterIds.includes(op.value) : null
                )}
              />
            </FormItem>
          )}
          {filterConfig && filterConfig.length > 1 && (
            <FormItem>
              <span>
                {mapi18n("gen_lbl_fsets")}
                {filterConfig[1].required && (
                  <span style={{ color: "red" }}> *</span>
                )}
              </span>

              <Select
                closeMenuOnSelect={!(filterConfig[1].select === "multi")}
                isMulti={filterConfig[1].select === "multi"}
                options={filterListOptions}
                onChange={handleFilterListChange}
                value={filterListOptions.filter((op) =>
                  filterListIds ? filterListIds.includes(op.value) : null
                )}
              />
            </FormItem>
          )}
          {timeframeConfig && (
            <FormItem>
              <span>
                {mapi18n("gen_lbl_tf")}
                <span style={{ color: "red" }}> *</span>
              </span>
              <Select
                options={timeframeOptions}
                onChange={handleTimeframeChange}
                value={timeframeOptions.find((t) => t.value === timeframe)}
              />
            </FormItem>
          )}
          {pagePath && (
            <>
              <FormItem>
                <span>{mapi18n("pp_lbl_entrykey")}</span>
                <Input
                  onChange={(e) => setEntryKey(e.target.value)}
                  value={entryKey}
                  noPadding
                />
                <Radio
                  options={ekOptions}
                  selected={selectedEKOption}
                  setSelected={setSelectedEKOption}
                />
              </FormItem>
              <FormItem>
                <span>{mapi18n("pp_lbl_endgoal")}</span>
                <Input
                  onChange={(e) => setEndGoal(e.target.value)}
                  value={endGoal}
                  noPadding
                />
                <Radio
                  options={egOptions}
                  selected={selectedEGOption}
                  setSelected={setSelectedEGOption}
                />
              </FormItem>
            </>
          )}
          {pageDistribution && (
            <>
              <FormItem>
                <span>{mapi18n("pp_lbl_enterurl")}</span>
                <Input
                  onChange={(e) => setEntryKey(e.target.value)}
                  value={entryKey}
                  noPadding
                />
              </FormItem>
              <FormItem>
                <span>{mapi18n("pp_lbl_reptype")}</span>
                <Select
                  options={pdOptions}
                  onChange={(value) => setSelectedPDOption(value)}
                  value={selectedPDOption}
                />
              </FormItem>
            </>
          )}
          {sessionPath && (
            <>
              <FormItem>
                <span>{mapi18n("form_lbl_name")}</span>
                <SessionListArea>
                  <SortableList
                    elements={sessionPathSites}
                    setElements={setSessionPathSites}
                    style={{ marginBottom: "1rem" }}
                  />
                  <AddSection>
                    <Input
                      onChange={(e) => setNewSessionPath(e.target.value)}
                      value={newSessionPath}
                      placeholder={"Enter Session Path..."}
                      noPadding
                    />
                    <Button
                      secondary
                      label="Add"
                      style={{ float: "right", backgroundColor: "white" }}
                      onClick={handleAddSessionPathSite}
                    />
                  </AddSection>
                </SessionListArea>
              </FormItem>
              <FormItem>
                <span>{mapi18n("pp_lbl_iskeyorentry")}</span>
                <Radio
                  options={ekOptions}
                  selected={selectedEKOption}
                  setSelected={setSelectedEKOption}
                />
              </FormItem>
            </>
          )}
          {displayModeRequired && (
            <FormItem>
              <span>{mapi18n("pp_lbl_dsplmode")}</span>
              <Select
                options={displayModeOptions}
                onChange={(value) => setDisplayMode(value)}
                value={displayMode}
              />
            </FormItem>
          )}
          {reportRequest && reportRequest.search_string && (
            <FormItem>
              <span>{"Search"}</span>
              <Input
                onChange={(e) => setSearchString(e.target.value)}
                value={searchString}
                noPadding
              />
            </FormItem>
          )}
        </RightColumn>
      </Body>
      <ActionRow>
        <Button tertiary label={mapi18n("gen_btn_can")} onClick={closeModal} />
        <Button
          primary
          label={mapi18n("prf_lbl_cnrep")}
          loading={waiting === "submit"}
          onClick={handleSubmit}
        />
      </ActionRow>
    </Container>
  );
};

export default NewReport;

const FormItem = styled.div`
  margin: 1rem 0;
  display: grid;

  > span {
    font-weight: 700;
    margin-bottom: 0.25rem;
  }
`;

const Container = styled.div`
  border-top: 1px solid #aaa;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid #aaa;
  display: grid;
  grid-template-columns: min-content 1fr;
`;

const SessionListArea = styled.div`
  background: var(--color-paper-rough);
  padding: 1rem;
`;

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0 1rem 3rem;
  width: 40vw;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 410px;
  margin: 1rem 3rem 1rem 0;
`;

const AddSection = styled.div`
  display: flex;
`;

/*
NEED either filter_id or filterlist_id

Demographic -> Country
Filter: 00. All

date_end	20201011
date_begin	20201005
product_id	si
filter_id	230399
timeframe_id	n
report_id	si_de_co
reportset_id	198693069629371

forEach required
- if date_end or date_begin - s'cool
- if product id = si
- if report_id set to this.id
- if filter_id {
	if (select = single : radio button , else checkbox)

timeframe.length == 1 then send timeframe_id = n
else 
*/
