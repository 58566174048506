import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Select from "react-select";

import Button from "components/Button";
import Input from "components/Input";
import Loading from "components/Loading";

import { ModalContext } from "context/Modal";
import { NotificationsContext } from "context/Notifications";
import { LocalizationContext } from "context/Localization";

//import { defaultPost, defaultGet } from "tools/axios";
import { useAxios } from "tools/axios";
import { objectToFormData } from "tools/misc";
import { xmlToObject, objectToXml } from "tools/xml";

const tabs = [
  {
    name: "newfilterset",
    label: "prf_lbl_cnfs",
  },
  {
    name: "editfiltersets",
    label: "prf_lbl_edfset",
  },
  {
    name: "localization",
    label: "prf_lbl_loc",
  },
];

const Preferences = ({
  tab,
  filters,
  logout,
  filterlist,
  reloadFilterlist,
}) => {
  const { defaultGet, defaultPost } = useAxios();
  const { addNotification } = useContext(NotificationsContext);
  const { mapi18n } = useContext(LocalizationContext);
  const { closeModal } = useContext(ModalContext);

  const [currentTab, setCurrentTab] = useState(tab ? tab : "newfilterset");
  const [localizationSave, setLocalizationSave] = useState(null);
  const [filtersetsSave, setFiltersetsSave] = useState(null);
  const [waiting, setWaiting] = useState(null);

  const saveLocalization = async () => {
    if (localizationSave) {
      try {
        setWaiting("submit");
        const response = await defaultPost(
          "preferences/general/save/",
          objectToFormData({ data: localizationSave, product_id: "si" })
        );
        addNotification("notification", "Please log in again");
        logout();
        closeModal();
      } catch (err) {
        setWaiting(null);
        addNotification("warning", "An error occurred");
      }
    }
  };

  const saveNewFilterlist = async () => {
    if (filtersetsSave) {
      try {
        setWaiting("submit");
        if (
          filterlist.find((fl) => fl.name === filtersetsSave.name) !== undefined
        ) {
          addNotification("warning", "Filterlist  already exists");
          setWaiting(null);
        } else {
          const response = await defaultPost(
            "preferences/filterlist/save/",
            objectToFormData({ data: filtersetsSave.xml, product_id: "si" })
          );

          addNotification("notification", "Filterlist saved");
          await reloadFilterlist();
          setWaiting(null);
          closeModal();
        }
      } catch (err) {
        console.error(err);
        setWaiting(null);
        closeModal();
        addNotification("warning", "An error occurred");
      }
    } else {
      addNotification("warning", mapi18n("mnu_btn_fl"));
    }
  };

  const saveExistingFilterset = async () => {
    if (filtersetsSave) {
      try {
        setWaiting("submit");
        const response = await defaultPost(
          "preferences/filterlist/save/",
          objectToFormData({ data: filtersetsSave.xml, product_id: "si" })
        );

        await reloadFilterlist();
        setWaiting(null);
        addNotification("notification", "Filterlist saved");
        closeModal();
      } catch (err) {
        setWaiting(null);
        addNotification("warning", "An error occurred");
      }
    } else {
      addNotification("warning", mapi18n("mnu_btn_fl"));
    }
  };

  const save = async () => {
    switch (currentTab) {
      case "localization":
        await saveLocalization();
        break;
      case "newfilterset":
        await saveNewFilterlist();
        break;
      case "editfiltersets":
        await saveExistingFilterset();
        break;
      default:
        console.log("nothing to save");
    }
  };

  return (
    <Container>
      <Body>
        <SidePanel
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          tabs={tabs}
          mapi18n={mapi18n}
        />
        {
          {
            newfilterset: (
              <FiltersetsForm
                key={234}
                allFilters={filters}
                setFiltersetsSave={setFiltersetsSave}
                addNotification={addNotification}
                func={"new"}
              />
            ),
            editfiltersets: (
              <FiltersetsForm
                key={567}
                allFilters={filters}
                setFiltersetsSave={setFiltersetsSave}
                filterlist={filterlist}
                addNotification={addNotification}
                reloadFilterlist={reloadFilterlist}
                closeModal={closeModal}
                func={"edit"}
              />
            ),
            localization: (
              <LocalizationForm setLocalizationSave={setLocalizationSave} />
            ),
          }[currentTab]
        }
      </Body>
      <ActionRow>
        <Button tertiary label={mapi18n("gen_btn_can")} onClick={closeModal} />
        <Button
          primary
          loading={waiting === "submit"}
          label={mapi18n("prf_btn_sav")}
          onClick={save}
        />
      </ActionRow>
    </Container>
  );
};

export default Preferences;

const SidePanel = ({ currentTab, setCurrentTab, tabs, mapi18n }) => {
  return (
    <PanelContainer>
      {tabs.map((tab, i) => (
        <PanelOption
          key={i}
          active={tab.name === currentTab}
          onClick={() => setCurrentTab(tab.name)}
        >
          {mapi18n(tab.label).toUpperCase()}
        </PanelOption>
      ))}
    </PanelContainer>
  );
};

const FiltersetsForm = ({
  setFiltersetsSave,
  allFilters,
  func,
  filterlist,
  addNotification,
  reloadFilterlist,
  closeModal,
}) => {
  const { defaultGet, defaultPost } = useAxios();
  const { mapi18n } = useContext(LocalizationContext);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("New Filterset");
  const [waiting, setWaiting] = useState(null);
  const [filterOptions, setFilterOptions] = useState(
    allFilters.filter.map((fi) => ({ value: fi.id, label: fi.name }))
  );
  const [filterlistOptions, setFilterlistOptions] = useState(
    filterlist
      ? filterlist.map((fl) => ({ value: fl.id, label: fl.name }))
      : null
  );
  const [selectedFilterlist, setSelectedFilterlist] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);
  useEffect(() => {
    (async () => {
      const response = await defaultPost(
        "preferences/filterlist/read/",
        objectToFormData({ product_id: "si" })
      );
      setLoading(false);
    })();
  }, []);

  const handleNameChange = (e) => {
    if (func === "new") {
      setName(e.target.value);
      formatUpdateFilterXml(selectedFilters, e.target.value);
    } else {
      setName(e.target.value);
      formatUpdateFilterXml(
        selectedFilters,
        filterlist.find((f) => f.id === selectedFilterlist).name,
        selectedFilterlist
      );

      setSelectedFilters(null);
      formatUpdateFilterXml(null);
    }
  };

  const handleFiltersChange = (value) => {
    if (func === "new") {
      if (value) {
        setSelectedFilters(value.map((v) => v.value));
        formatUpdateFilterXml(
          value.map((val) => val.value),
          name
        );
      } else {
        setSelectedFilters(null);
        formatUpdateFilterXml(null);
      }
    } else {
      if (value) {
        setSelectedFilters(value.map((v) => v.value));
        formatUpdateFilterXml(
          value.map((val) => val.value),
          filterlist.find((f) => f.id === selectedFilterlist).name,
          selectedFilterlist
        );
      } else {
        setSelectedFilters(null);
        formatUpdateFilterXml(null);
      }
    }
  };

  const handleFilterlistChange = (value) => {
    if (value) {
      setSelectedFilterlist(value.value);
      setSelectedFilters(filterlist.find((fi) => fi.id == value.value).filters);
      formatUpdateFilterXml(null);
    } else {
      setSelectedFilterlist(null);
      setSelectedFilters(null);
      formatUpdateFilterXml(null);
    }
  };

  // if no id, new filter is created
  const formatUpdateFilterXml = (filters, name, id) => {
    if (filters && name) {
      let requestObject = {
        preferences: {
          filterlist: {
            $: {
              id: id ? id : 0,
              name: name,
            },
          },
        },
      };
      requestObject.preferences.filterlist.filter = filters.map((fi) => ({
        $: { id: fi },
      }));
      const requestXml = objectToXml(requestObject);
      setFiltersetsSave({ xml: requestXml, name: name });
    } else {
      setFiltersetsSave(null);
    }
  };

  const handleDeleteFilterlist = async () => {
    try {
      setWaiting("delete");
      const requestObject = {
        preferences: {
          filterlist: {
            $: {
              id: selectedFilterlist,
            },
          },
        },
      };
      const requestXml = objectToXml(requestObject);
      const response = await defaultPost(
        "preferences/filterlist/del/",
        objectToFormData({ data: requestXml, product_id: "si" })
      );
      setWaiting(null);
      addNotification("notification", "Filterlist deleted");
      await reloadFilterlist();
      closeModal();
    } catch (err) {
      setWaiting(null);
      addNotification("error", "An error occurred");
    }
  };

  return (
    <FormContainer>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <>
          {func === "new" ? (
            <>
              <div>
                <label>{mapi18n("form_lbl_name")}</label>
                <Input onChange={handleNameChange} value={name} noPadding />
              </div>
              <div>
                <label>{mapi18n("gen_lbl_fls")}</label>
                <Select
                  options={filterOptions}
                  onChange={handleFiltersChange}
                  closeMenuOnSelect={false}
                  isMulti={true}
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <label>{mapi18n("form_lbl_name")}</label>
                <Select
                  options={filterlistOptions}
                  onChange={handleFilterlistChange}
                />
              </div>
              <div>
                <label>{mapi18n("gen_lbl_fls")}</label>
                <Select
                  options={filterOptions}
                  onChange={handleFiltersChange}
                  value={
                    selectedFilters
                      ? filterOptions.filter((fo) =>
                          selectedFilters.includes(fo.value)
                        )
                      : null
                  }
                  closeMenuOnSelect={false}
                  isMulti={true}
                />
              </div>
              {selectedFilterlist && (
                <div>
                  <Button
                    secondary
                    label={mapi18n("prf_btn_del")}
                    loading={waiting === "delete"}
                    onClick={handleDeleteFilterlist}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </FormContainer>
  );
};

const LocalizationForm = ({ setLocalizationSave }) => {
  const {
    language,
    availableEncodings,
    availableLanguages,
    mapi18n,
  } = useContext(LocalizationContext);
  const { defaultGet, defaultPost } = useAxios();

  const [encodingOptions, __] = useState(
    availableEncodings.map((ae) => ({ value: ae.id, label: ae.name }))
  );

  const [languageOptions, _] = useState(
    availableLanguages.map((al) => ({ value: al.value, label: al.name }))
  );

  const [selectedEncoding, setSelectedEncoding] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions.map((lo) => lo.value === language)
  );
  const [loading, setLoading] = useState(true);

  const formatXml = (enc, lang) => {
    let requestObject = {
      preferences: {
        general: {
          language: {
            $: {
              value: lang,
            },
          },
          encoding: {
            $: {
              id: enc,
            },
          },
        },
      },
    };
    let requestXml = objectToXml(requestObject);
    setLocalizationSave(requestXml);
  };

  const handleEncodingChange = (value) => {
    setSelectedEncoding(value.value);
    formatXml(value.value, selectedLanguage);
  };

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value.value);
    formatXml(selectedEncoding, value.value);
  };

  useEffect(() => {
    (async () => {
      const response = await defaultGet("preferences/general/read/");
      const responseObject = await xmlToObject(response.data);
      setSelectedLanguage(
        responseObject.preferences.general[0].language[0].$.value
      );
      setSelectedEncoding(
        responseObject.preferences.general[0].encoding[0].$.id
      );
      setLoading(false);
    })();
  }, []);
  return (
    <FormContainer>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <>
          <div>
            <label>{mapi18n("prf_lbl_lang")}</label>
            <Select
              options={languageOptions}
              onChange={handleLanguageChange}
              defaultValue={languageOptions.find(
                (el) => el.value === selectedLanguage
              )}
            />
          </div>
          <div>
            <label>{mapi18n("prf_lbl_enc")}</label>
            <Select
              options={encodingOptions}
              onChange={handleEncodingChange}
              defaultValue={encodingOptions.find(
                (el) => el.value === selectedEncoding
              )}
            />
          </div>
        </>
      )}
    </FormContainer>
  );
};

const Container = styled.div`
  border-top: 1px solid #aaa;
  width: 50vw;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  padding: 1rem 0rem;
  display: grid;
  grid-template-columns: 25% 1fr;
  border-bottom: 1px solid #aaa;
`;

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    > label {
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
  }
`;

const PanelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  border-right: 1px solid #aaa;
`;

const PanelOption = styled.span`
  color: ${(p) => (p.active ? "var(--color-primary)" : "#515151")};
  font-size: ${(p) => (p.active ? "var(--font-size-5)" : "var(--font-size-6)")};
  cursor: pointer;
  width: 80%;
  font-weight: 700;
  margin: 1rem 0;
`;
