import styled from "styled-components";
import loading from "../../loading.png";

const Loading = ({ label, disabled, button, ...rest }) => {
  return (
    <LoadingContainer button={button}>
      <img src={loading} alt={"Loading..."} />
    </LoadingContainer>
  );
};

export default Loading;

const LoadingContainer = styled.div`
  display: grid;
  ${(p) => !p.button && "margin: 1rem"};
  place-items: center;
  animation: rotation 3s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  > img {
    width: ${(p) => (p.button ? "1rem" : "90px")};
    ${(p) => p.button && "filter: grayscale(90%)  brightness(1.5);"}
  }
`;
