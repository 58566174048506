import styled from "styled-components";

const Input = ({ label, disabled, noPadding, ...rest }) => {
  return (
    <InputContainer noPadding={noPadding}>
      {label && <InputLabel htmlFor={label}>{label.toUpperCase()}</InputLabel>}
      <InputInput id={label} {...rest}></InputInput>
    </InputContainer>
  );
};

export default Input;

const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  ${(p) =>
    p.noPadding
      ? null
      : "margin: var(--margin-input-default); padding: var(--padding-input-default);"}
`;

const InputLabel = styled.label`
  font-weight: 700;
  /* width: 5rem; */
  margin-right: 1rem;
  color: var(--color-label-primary);
  cursor: pointer;
`;

const InputInput = styled.input`
  border: 0;
  font-size: 16px;
  padding: 0.5rem;
  width: 100%;
  border-bottom: 1px solid var(--color-label-primary);
  &:hover {
    background-color: white;
  }
  &:focus {
    background-color: white;
  }
`;
