import styled from "styled-components";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 *
 * @param {Array} tabs - Array containing tabs to display. Elements should have at minimum: name, type, id
 * @param {Number} current - Currently active tab
 * @param {Function} set - Function to set the currently active tab
 * @param {Function} remove - Function to remove a tab by id
 */

const Tabs = ({ tabs, current, set, remove }) => {
  return (
    <Container>
      {tabs.map((t, i) => (
        <Tab
          tabId={t.reportId}
          active={current === i}
          name={t.name}
          type={t.type ? t.type : "report"}
          onClick={() => (current !== i ? set(i) : null)}
          remove={remove}
          key={i}
        />
      ))}
    </Container>
  );
};

export default Tabs;

const Container = styled.div`
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const Tab = ({ tabId, type, active, name, remove, ...rest }) => {
  return (
    <TabContainer active={active} type={type} {...rest}>
      <span>{name}</span>
      {active && type !== "dashboard" && (
        <i>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => remove(tabId)}
            color={type == "dashboard" ? "white" : "black"}
          />
        </i>
      )}
    </TabContainer>
  );
};

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  ${(p) => !p.active && "cursor: pointer"};
  ${(p) =>
    !p.active &&
    "&:hover { background-color: var(--color-paper-rough-highlight); }"};
  width: var(--tab-width);
  height: var(--tab-height);
  background-color: ${(p) =>
    !p.active
      ? "var(--color-paper-rough)"
      : p.type == "report"
      ? "var(--color-paper)"
      : "var(--color-primary)"};
  > span {
    color: ${(p) =>
      !p.active
        ? "black"
        : p.type == "report"
        ? "black"
        : "var(--color-paper)"};
    font-weight: ${(p) => (p.active ? "700" : "300")};
    font-size: var(--font-size-6);
    user-select: none;
  }
  > i {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
    cursor: pointer;
    &:hover {
      background-color: var(--color-hover-opacity);
    }
  }
`;
