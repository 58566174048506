import { createContext, useContext, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'

import Notification from 'components/Notification'
import styled from 'styled-components'

export const NotificationsContext = createContext()
export const NotificationsInternalContext = createContext()
const { Provider } = NotificationsContext
const InternalProvider = NotificationsInternalContext.Provider

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([])

  const addNotification = (type, message) => {
    setNotifications((n) => [
      ...n,
      {
        id: `${Math.random() * 1000}`,
        type,
        message,
        state: 'active',
      },
    ])
  }

  const removeNotification = (id) => {
    setNotifications((notifs) => notifs.filter((n) => n.id !== id))
  }

  const externalValue = useMemo(() => ({ addNotification }), [])

  return (
    <Provider value={externalValue}>
      <InternalProvider value={{ notifications, removeNotification }}>
        {children}
      </InternalProvider>
    </Provider>
  )
}

const Notifications = () => {
  const { notifications } = useContext(NotificationsInternalContext)
  return ReactDOM.createPortal(
    <NHolder>
      {notifications.map((n) => (
        <Notification
          key={n.id}
          id={n.id}
          type={n.type}
          message={n.message}
          state={n.state}
        />
      ))}
    </NHolder>,
    document.querySelector('#notifications-root')
  )
}

export default Notifications

const NHolder = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: var(--z-index-notifications);
`
