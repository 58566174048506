import { useState } from "react";

import CreatableSelect from "react-select/creatable";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const Creatable = ({ value, setValue }) => {
  const [inputValue, setInputValue] = useState("");
  const handleChange = (value, actionMeta) => {
    setValue(value);
  };
  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };
  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
      case ",":
        setInputValue("");
        setValue([...value, createOption(inputValue)]);
        event.preventDefault();
        break;
      default:
    }
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder="Type something and press enter..."
      value={value}
    />
  );
};

export default Creatable;
