import { useContext, useEffect, useState } from 'react'

import Dashboard from 'components/Dashboard'
import Header from 'components/Header'
import NewReport from 'components/Modal/components/NewReport'
import Report from 'components/Report'
import Tabs from 'components/Tabs'
import { ModalContext } from 'context/Modal'
import { NotificationsContext } from 'context/Notifications'
import mapReportResponse from 'mapApi/mapReportResponse'
import styled from 'styled-components'
// import { defaultPost } from "tools/axios";
import { useAxios } from 'tools/axios'
import { objectToFormData } from 'tools/misc'
import { objectToXml, xmlToObject } from 'tools/xml'

import {
  faCaretDown,
  faCaretRight,
  faDesktop,
  faFileAlt,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/*
    Tab type: dashboard | report;
*/

const DashboardPage = ({
  username,
  reportTypes,
  interfaceMapping,
  timeframes,
  dateLimits,
  filters,
  filterlist,
  dashboard,
  loadDashboard,
  loadFilterlist,
  logout,
  mapi18n,
}) => {
  const { defaultPost } = useAxios()
  const { addNotification } = useContext(NotificationsContext)
  const { closeModal } = useContext(ModalContext)

  const [reports, setReports] = useState([
    { name: mapi18n('gen_lbl_dsh'), type: 'dashboard' },
  ])
  const [reportRequests, setReportRequests] = useState([
    { placeholder: 'dummy' },
  ])
  const [reportOptionalFields, setReportOptionalFields] = useState([
    { placeholder: 'dummy' },
  ])

  const [currentReport, setCurrentReport] = useState(0)
  const [reportAdded, setReportAdded] = useState(false)

  const [catOpen, setCatOpen] = useState(null)

  const remove = (id) => {
    setReportRequests((rrs) => rrs.filter((r, i) => i !== currentReport))
    setReportOptionalFields((rof) => rof.filter((r, i) => i !== currentReport))
    setCurrentReport((r) => r - 1)
    setReports((rs) => rs.filter((r) => r.reportId !== id))
  }

  const removeOldest = () => {
    let sortedReports = reports.sort((a, b) => a.timestamp - b.timestamp)
    // min = first of array, max = last of array
    let idToRemove = sortedReports[1].reportId
    let deletionIndex = reports.findIndex((r) => r.reportId == idToRemove)
    if (deletionIndex == currentReport) {
      setCurrentReport(1)
    }
    setReports((rs) => rs.filter((r, i) => i !== deletionIndex))
    setReportRequests((rrs) => rrs.filter((r, i) => i !== deletionIndex))
    setReportOptionalFields((rof) => rof.filter((r, i) => i !== deletionIndex))
    addNotification('warning', mapi18n('msg_err_tab'))
  }

  useEffect(() => {
    if (reportAdded) {
      setCurrentReport(reportOptionalFields.length - 1)
      setReportAdded(false)
    }
  }, [reportAdded, reportOptionalFields])

  const fetchReport = async (paramsObj, index, optionalFields) => {
    try {
      let newReport

      const formData = objectToFormData(paramsObj)
      newReport = await defaultPost('report/report/read/', formData)

      const reportFromXML = await xmlToObject(newReport.data)

      if (index) {
        const newReportData = mapReportResponse(
          reportFromXML,
          paramsObj,
          getReport
        )
        newReportData.timestamp = Date.now()
        if (reports.length > 14) {
          // means next addition will overflow, so first delete element
          setCurrentReport(0)
          removeOldest()
        }
        setReports((r) => {
          r.splice(currentReport, 0, newReportData)
          return r
        })
        setReportRequests((rr) => {
          rr.splice(currentReport, 0, paramsObj)
          return rr
        })
        if (optionalFields) {
          setReportOptionalFields((rof) => {
            rof.splice(currentReport, 0, optionalFields)
            return rof
          })
        } else {
          setReportOptionalFields((rof) => {
            rof.splice(currentReport, 0, {})
            return rof
          })
        }
      } else {
        const newReportData = mapReportResponse(
          reportFromXML,
          paramsObj,
          getReport
        )
        newReportData.timestamp = Date.now()
        if (reports.length > 14) {
          // means next addition will overflow, so first delete element
          setCurrentReport(0)
          removeOldest()
        }
        setReports((r) => [...r, newReportData])
        setReportRequests((rr) => [...rr, paramsObj])
        if (optionalFields) {
          setReportOptionalFields((rof) => [...rof, optionalFields])
        } else {
          setReportOptionalFields((rof) => [...rof, {}])
        }
      }
      setReportAdded(true)
    } catch (err) {
      addNotification('error', 'An error occurred')
    }
  }

  const getReport = async (paramsObj, index, optionalFields) => {
    await fetchReport(paramsObj, index, optionalFields)
    //setCurrentReport(reports.length);
  }

  const addToDashboard = async (reportRequest, mode, period, order) => {
    let requestObject = {
      preferences: {
        dashboard: {
          report: {
            $: {
              order: `${order}`,
            },
            parameters: {
              $: {
                id: 'main_parameters',
              },
            },
            mode: {
              $: {
                id: `${mode}`,
              },
            },
            period: {
              $: {
                id: '21',
              },
            },
          },
        },
      },
    }
    for (const [key, value] of Object.entries(reportRequest)) {
      requestObject.preferences.dashboard.report.parameters[key] = {
        $: { value },
      }
    }
    const requestXml = objectToXml(requestObject)

    const formData = objectToFormData({ data: requestXml, product_id: 'si' })
    const response = await defaultPost('preferences/dashboard/save/', formData)
    await loadDashboard()
    closeModal()
    setCurrentReport(0)
  }

  const removeFromDashboard = async (order) => {
    try {
      const formData = objectToFormData({
        data: `<preferences><dashboard><report order="${order}"/></dashboard></preferences>`,
        product_id: 'si',
      })
      const response = await defaultPost('preferences/dashboard/del/', formData)
      await loadDashboard()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Header
        logout={logout}
        filters={filters}
        filterlist={filterlist}
        loadFilterlist={loadFilterlist}
        username={username}
      />
      {reports.length && (
        <Container>
          <SidebarArea>
            <DashboardButton onClick={() => setCurrentReport(0)}>
              <FontAwesomeIcon
                icon={faDesktop}
                color={'var(--color-primary)'}
              />
              <span>{mapi18n('gen_lbl_dsh').toUpperCase()}</span>
            </DashboardButton>
            <ReportPanel
              reportTypes={reportTypes}
              filters={filters}
              filterlist={filterlist}
              getReport={getReport}
              reports={reports}
              dateLimits={dateLimits}
              setCurrentReport={setCurrentReport}
              mapi18n={mapi18n}
            />
          </SidebarArea>
          <BodyArea>
            <BodyContainer>
              <Tabs
                tabs={reports}
                current={currentReport}
                set={setCurrentReport}
                remove={remove}
              />
              {reports[currentReport] &&
              reports[currentReport].type === 'report' ? (
                <Report
                  mapi18n={mapi18n}
                  report={reports[currentReport]}
                  getReport={getReport}
                  reportRequest={reportRequests[currentReport]}
                  reportOptionalFields={reportOptionalFields[currentReport]}
                  index={currentReport}
                  addToDashboard={addToDashboard}
                  dashboard={dashboard}
                  key={reports[currentReport].reportId}
                  dateLimits={dateLimits}
                  filters={filters}
                  filterlist={filterlist}
                  reportType={reportTypes
                    .find((el) =>
                      el.report.find(
                        (r) => r.id == reportRequests[currentReport].report_id
                      )
                    )
                    ?.report.find(
                      (el) => el.id == reportRequests[currentReport].report_id
                    )}
                />
              ) : (
                <Dashboard
                  dashboard={dashboard}
                  removeFromDashboard={removeFromDashboard}
                  mapi18n={mapi18n}
                />
              )}
            </BodyContainer>
          </BodyArea>
        </Container>
      )}
    </>
  )
}

export default DashboardPage

const ReportPanel = ({
  reportTypes,
  filters,
  filterlist,
  getReport,
  reports,
  dateLimits,
  setCurrentReport,
  mapi18n,
}) => {
  const [catOpen, setCatOpen] = useState(null)
  return (
    <ReportPane>
      <div>
        <FontAwesomeIcon icon={faFileAlt} color={'var(--color-paper)'} />
        <span>{mapi18n('mnu_btn_rep').toUpperCase()}</span>
      </div>
      <div>
        {reportTypes ? (
          reportTypes.map((r, i) => (
            <Category
              open={catOpen}
              setOpen={setCatOpen}
              reportTypes={reportTypes}
              reportsLength={reports.length}
              filters={filters}
              filterlist={filterlist}
              key={i}
              name={r.name}
              dateLimits={dateLimits}
              subcategories={r.report}
              getReport={getReport}
              setCurrentReport={setCurrentReport}
            />
          ))
        ) : (
          <span>Loading</span>
        )}
      </div>
    </ReportPane>
  )
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-background);
  display: grid;
  grid-template-columns: 20% 80%;
`

const SidebarArea = styled.div`
  padding: 1rem;
`

const BodyArea = styled.div`
  padding: 1rem;
  max-width: 100%;
  overflow-x: scroll;
`

const BodyContainer = styled.div`
  display: grid;
  grid-template-rows: 45px 1fr;
`

const DashboardButton = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 1rem 1rem;
  background-color: var(--color-paper);
  border: 1px solid var(--color-primary);
  > span {
    margin-left: 1rem;
    font-size: var(--font-size-6);
    color: var(--color-primary);
    font-weight: 700;
  }
  &:hover {
    background-color: var(--color-hover-opacity);
  }
  margin-bottom: 2rem;
`

const ReportPane = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow-1);
  border: 1px solid var(--color-primary);
  > div:first-child {
    width: 100%;
    padding: 1rem 1rem;
    background-color: var(--color-primary);

    > span {
      margin-left: 1rem;
      font-size: var(--font-size-6);
      color: var(--color-paper);
      font-weight: 700;
    }
  }
`

const Category = ({
  name,
  subcategories,
  reportTypes,
  filters,
  filterlist,
  open,
  setOpen,
  dateLimits,
  getReport,
  setCurrentReport,
  reportsLength,
}) => {
  const { openModal } = useContext(ModalContext)

  const handleOpen = () => {
    if (open === name) {
      setOpen(null)
    } else {
      setOpen(name)
    }
  }
  return (
    <CategoryContainer>
      <div onClick={handleOpen}>
        <FontAwesomeIcon
          icon={open === name ? faCaretDown : faCaretRight}
          color={'black'}
        />
        <span>{name.toUpperCase()}</span>
      </div>
      {open === name &&
        subcategories.map((s, i) => (
          <div
            key={i}
            onClick={() =>
              openModal(
                <NewReport
                  report={s}
                  reportsLength={reportsLength}
                  filters={filters}
                  filterlist={filterlist}
                  dateLimits={dateLimits}
                  getReport={getReport}
                  setCurrentReport={setCurrentReport}
                />,
                `New Report`,
                s.name
              )
            }
          >
            <FontAwesomeIcon icon={faPlus} color={'var(--color-primary)'} />
            <span>{s.name.toUpperCase()}</span>
          </div>
        ))}
    </CategoryContainer>
  )
}

const CategoryContainer = styled.div`
  background-color: var(--color-paper);
  > div {
    width: 100%;
    padding: 1rem 1rem 1rem 2rem;
    cursor: pointer;
    &:hover {
      background-color: var(--color-hover-opacity);
    }
    > span {
      margin-left: 1rem;
      font-size: var(--font-size-6);
      color: var(--color-primary);
      font-weight: 700;
    }
  }
  > div:first-child {
    padding: 1rem 1rem;

    > span {
      margin-left: 1rem;
      font-size: var(--font-size-6);
      color: black;
      font-weight: 700;
    }
  }
`
