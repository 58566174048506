import { useMemo, useEffect, useState, createContext, useContext } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import styled from "styled-components";

import Button from "components/Button";
import Select from "react-select";

import { LocalizationContext } from "context/Localization";

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Note: columns and data MUST be named as such

const rowOptions = [
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
  }),

  control: () => ({
    display: "flex",
    cursor: "pointer",
    width: 80,
  }),

  indicatorContainer: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...provided,
      color: "white",
      fontSize: "var(--font-size-6)",
      opacity,
      transition,
    };
  },
};

// Context for Page Detail tables so checkboxes work

export const CheckboxContext = createContext();
const { Provider } = CheckboxContext;

// Drilldown Context to pass optionalFields for search fields

export const OptionalFieldsContext = createContext();
const OptionalFieldsProvider = OptionalFieldsContext.Provider;

const Table = ({
  table,
  report,
  getReport,
  reportRequest,
  reportOptionalFields,
}) => {
  const { mapi18n } = useContext(LocalizationContext);
  const data = useMemo(() => table.data, [table.data]);
  const columns = useMemo(
    () =>
      table.columns.map((col) => ({
        ...col,
      })),
    [table.columns]
  );
  const [pageDetailChecks, setPageDetailChecks] = useState([]);

  const [isFetching, setIsFetching] = useState(false);

  const handleGetMultiPageReport = async (reportId) => {
    setIsFetching(true);
    await getReport(
      {
        ...reportRequest,
        report_id: reportId,
        detail_id: pageDetailChecks.join(","),
      },
      null,
      reportOptionalFields
    );
    setIsFetching(false);
  };

  const initialState = {
    pageSize: report.parameters.report_id === "si_pp_pg" ? 25 : 10,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, initialState }, useSortBy, usePagination);

  const handlePageDetailChecks = (name) => {
    if (pageDetailChecks.find((v) => v === name)) {
      setPageDetailChecks((pdc) => pdc.filter((el) => el !== name));
    } else {
      setPageDetailChecks((pdc) => [...pdc, name]);
    }
  };

  useEffect(() => {
    table.columns.forEach((col) => {
      if (col.isSortedd) {
        setSortBy([
          {
            id: col.accessor,
            desc: col.isSorteddDesc,
          },
        ]);
      }
    });
  }, []);

  const changeRowAmount = (value) => {
    setPageSize(value.value);
  };

  return (
    <Container>
      <OptionalFieldsProvider value={{ optionalFields: reportOptionalFields }}>
        <Provider value={{ pageDetailChecks, handlePageDetailChecks }}>
          <div>
            <StyledTable
              allowExpansion={report.parameters.report_id === "si_pp_pg"}
              {...getTableProps()}
            >
              <colgroup>
                {table.columns.map((col) => (
                  <col span="1" style={{ width: `${col.width}%` }}></col>
                ))}
              </colgroup>
              <THead>
                {headerGroups.map((headerGroup) => (
                  <>
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, i) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div style={{ display: "flex" }}>
                            {column.render("Header")}
                            <>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <FontAwesomeIcon
                                    icon={faAngleDown}
                                    style={{ marginLeft: "0.5rem" }}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faAngleUp}
                                    style={{ marginLeft: "0.5rem" }}
                                  />
                                )
                              ) : (
                                ""
                              )}
                            </>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </>
                ))}
              </THead>
              <TBody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </TBody>
              {table.columns[0].Footer && (
                <TFoot>
                  {headerGroups.map((headerGroup) => (
                    <>
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            <div style={{ display: "flex" }}>
                              {column.render("Footer")}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </>
                  ))}
                </TFoot>
              )}
            </StyledTable>
          </div>
          <ControlRow>
            <span>Rows</span>
            <Select
              styles={customStyles}
              options={rowOptions}
              defaultValue={rowOptions[0]}
              onChange={changeRowAmount}
              menuPlacement={"top"}
            />
            <span>|</span>
            <span>{"Page "}</span>
            <span style={{ fontSize: "var(--font-size-6)" }}>
              {pageIndex + 1}
            </span>
            <span>|</span>
            <Button
              primary
              icon={faAngleDoubleLeft}
              off={!canPreviousPage}
              onClick={previousPage}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            />
            <Button
              primary
              icon={faAngleDoubleRight}
              off={!canNextPage}
              onClick={nextPage}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            />
          </ControlRow>
          {report.parameters.report_id === "si_pg_dt_dn" && (
            <Button
              primary
              label={mapi18n("mnu_btn_sub")}
              loading={isFetching}
              onClick={() => handleGetMultiPageReport("si_pg_dt_dn_mp")}
            />
          )}
          {report.parameters.report_id === "si_pg_dt" && (
            <Button
              primary
              label={mapi18n("mnu_btn_sub")}
              loading={isFetching}
              onClick={() => handleGetMultiPageReport("si_pg_dt_mp")}
            />
          )}
        </Provider>
      </OptionalFieldsProvider>
    </Container>
  );
};

export default Table;

const Container = styled.div``;

const StyledTable = styled.table`
  ${(p) => (p.allowExpansion ? null : "width: 100%;")}
  border-spacing: 0;
  table-layout: fixed;
`;

const TFoot = styled.tfoot`
  > tr {
    background-color: var(--color-dashboard);
    > th {
      text-align: left;
      font-size: var(--font-size-7);
      padding: 0.25rem 0.5rem;
      overflow: hidden;
      color: white;
    }
  }
`;

const THead = styled.thead`
  > tr {
    > th {
      text-align: left;
      font-size: var(--font-size-7);
      padding: 0.25rem 0.5rem;
      overflow: hidden;
    }
  }
`;

const TBody = styled.tbody`
  > tr {
    > td {
      font-size: var(--font-size-7);
      padding: 0.25rem 0.5rem;
      overflow: hidden;
    }
  }
  > tr:nth-child(odd) {
    background-color: var(--color-paper-rough);
    > td {
      font-size: var(--font-size-7);
      padding: 0.25rem 0.5rem;
    }
  }
`;

const ControlRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0.25rem 0.5rem;
  background-color: var(--color-primary);
  > span {
    color: white;
    font-size: var(--font-size-7);
  }
  > * {
    margin: 0rem 0.5rem;
  }
`;
