import { useContext } from 'react'

import axios from 'axios'
import { NotificationsContext } from 'context/Notifications'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 60000,
})

api.defaults.withCredentials = true

/**
 * Sends POST request with some defaults set
 * @param {string} url
 * @param {FormData} formData
 * @returns {Promise}
 */

export const defaultPost = async (url, formData = null) =>
  api({
    method: 'post',
    url: url /* + "?product_id=si"*/,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })

export const defaultGet = async (url) =>
  api({
    method: 'get',
    url: url,
    headers: { 'Content-Type': 'multipart/form-data' },
  })

// Hooks implementation

export const useAxios = () => {
  const { addNotification } = useContext(NotificationsContext)
  const defaultPost = async (url, formData = null) => {
    try {
      const response = await api({
        method: 'post',
        url: url /* + "?product_id=si"*/,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return response
    } catch (err) {
      addNotification('error', err.message)
    }
  }

  const defaultGet = async (url) => {
    try {
      const response = await api({
        method: 'get',
        url: url,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return response
    } catch (err) {
      addNotification('error', err.message)
    }
  }

  return { defaultPost, defaultGet }
}
