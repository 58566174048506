const mapLoginResponse = (data) => {
  const interfaceMapping = data.login.i18n[0].tr.map((el) => ({
    id: el.$.id,
    value: el.$.value,
  }));
  const availableLanguages = data.login.languages[0].language.map((lg) => ({
    name: lg.$.name,
    value: lg.$.value,
  }));
  const availableEncodings = data.login.encodings[0].encoding.map((ec) => ({
    id: ec.$.id,
    name: ec.$.name,
  }));
  return {
    interfaceMapping,
    availableEncodings,
    availableLanguages,
    username: data.login.$.username,
    language: data.login.i18n[0].$.language,
  };
};

export default mapLoginResponse;
