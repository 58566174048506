import { useState, useContext, useMemo, useEffect } from "react";
import styled from "styled-components";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import format from "date-fns/format";
import en from "date-fns/locale/en-GB";
import ja from "date-fns/locale/ja";

import Button from "components/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

import { metrixDateToJsDate } from "tools/misc";

import { LocalizationContext } from "context/Localization";
import { NotificationsContext } from "context/Notifications";

import "react-datepicker/dist/react-datepicker.css";

import "./styles.css";

registerLocale("ja", ja);

const DatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  min,
  max,
}) => {
  const { language, mapi18n } = useContext(LocalizationContext);
  const { addNotification } = useContext(NotificationsContext);
  const [currentMonth, setCurrentMonth] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const years = useMemo(() => {
    const firstDate = new Date(min);
    const firstYear = firstDate.getFullYear();
    const lastYear = new Date().getFullYear();
    let years = [];
    for (let i = firstYear; i < lastYear + 1; i++) {
      years.push(i);
    }
    return years; //range(1990, getYear(new Date()) + 1, 1);
  }, []);

  const months = useMemo(
    () => [
      mapi18n("cal_mnth_jan"),
      mapi18n("cal_mnth_feb"),
      mapi18n("cal_mnth_mar"),
      mapi18n("cal_mnth_apr"),
      mapi18n("cal_mnth_may"),
      mapi18n("cal_mnth_jun"),
      mapi18n("cal_mnth_jul"),
      mapi18n("cal_mnth_aug"),
      mapi18n("cal_mnth_sep"),
      mapi18n("cal_mnth_oct"),
      mapi18n("cal_mnth_nov"),
      mapi18n("cal_mnth_dec"),
    ],
    []
  );

  useEffect(() => {
    if (!currentMonth) {
      let date;
      if (startDate) {
        date = new Date(startDate);
      } else {
        date = new Date();
      }
      let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDayOfMonth = new Date(
        firstDate.getFullYear(),
        firstDate.getMonth() + 1,
        0
      );
      if (lastDayOfMonth > new Date(max)) {
        setCurrentMonth([firstDate, new Date(max)]);
      } else {
        setCurrentMonth([firstDate, lastDayOfMonth]);
      }
    }
  }, []);

  const setMonth = () => {
    if (currentMonth) {
      onChange(currentMonth);
    }
  };

  return (
    <OuterShell>
      <PickerWrapper>
        <Container>
          <ReactDatePicker
            locale={language.substr(0, 2)}
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            minDate={min}
            maxDate={max}
            selectsRange
            inline
            showWeekNumbers
            onWeekSelect={(firstDayOfWeek, weekNumber, event) => {
              let first = new Date(firstDayOfWeek.getTime());
              let endDateLocal = first.setDate(first.getDate() + 6);
              let dates;
              if (first <= new Date(max)) {
                if (endDateLocal > new Date(max)) {
                  dates = [firstDayOfWeek, new Date(max)];
                } else {
                  dates = [firstDayOfWeek, new Date(endDateLocal)];
                }

                onChange(dates);
              } else {
                addNotification("warning", "Outside date range");
              }
            }}
            onMonthChange={(date) => {
              let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
              let lastDayOfMonth = new Date(
                firstDate.getFullYear(),
                firstDate.getMonth() + 1,
                0
              );
              let dates;
              if (lastDayOfMonth > new Date(max)) {
                dates = [firstDate, new Date(max)];
              } else {
                dates = [firstDate, lastDayOfMonth];
              }
              setCurrentMonth(dates);
            }}
            onYearChange={(date) => {
              let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
              let lastDayOfMonth = new Date(
                firstDate.getFullYear(),
                firstDate.getMonth() + 1,
                0
              );
              let dates;
              if (lastDayOfMonth > new Date(max)) {
                dates = [firstDate, new Date(max)];
              } else {
                dates = [firstDate, lastDayOfMonth];
              }
              setCurrentMonth(dates);
            }}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  tertiary
                  onClick={decreaseMonth}
                  icon={faAngleLeft}
                  off={prevMonthButtonDisabled}
                />

                <select
                  style={{
                    border: "none",
                    margin: "0 0.5rem",
                    fontSize: "var(--font-size-6)",
                    fontWeight: 700,
                  }}
                  value={months[new Date(date).getMonth()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <select
                  style={{
                    border: "none",
                    margin: "0 0.5rem",
                    fontSize: "var(--font-size-6)",
                    fontWeight: 700,
                  }}
                  value={new Date(date).getFullYear()}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <Button
                  tertiary
                  onClick={increaseMonth}
                  off={nextMonthButtonDisabled}
                  icon={faAngleRight}
                />
              </div>
            )}
          />
        </Container>
        {/*
        <Row>
          <div>
            <span>From</span>
            <input value={startDate} />
          </div>
          <div>
            <span>To</span>
            <input value={endDate} />
          </div>
        </Row>
        */}
      </PickerWrapper>
      <Button secondary label={mapi18n("cal_hnt_selm")} onClick={setMonth} />
    </OuterShell>
  );
};

export default DatePicker;

const OuterShell = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
`;

const Container = styled.div`
  /* Reaches into React DatePicker to center horizontally */
  > div {
    display: flex;
    justify-content: center;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    justify-content: space-between;
    width: 50%;
    > input {
      width: 50%;
    }
  }
`;

export const DropdownDatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  apply,
  min,
  max,
  waiting,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const { language, mapi18n } = useContext(LocalizationContext);
  return (
    <>
      <Wrapper {...rest}>
        <Header open={open} onClick={() => setOpen((o) => !o)}>
          <HeaderInternal>
            {language.substr(0, 2) === "en" && (
              <span>{`${format(startDate, "MMM do, y", {
                locale: { en: en, ja: ja }[language.substr(0, 2)],
              })}${
                endDate
                  ? " - " +
                    format(endDate, "MMM do, y", {
                      locale: { en: en, ja: ja }[language.substr(0, 2)],
                    })
                  : " - " + mapi18n("form_lbl_seldate")
              }`}</span>
            )}
            {language.substr(0, 2) === "ja" && (
              <span>{`${format(startDate, "yyyy/MM/dd", {
                locale: { en: en, ja: ja }[language.substr(0, 2)],
              })}${
                endDate
                  ? " - " +
                    format(endDate, "yyyy/MM/dd", {
                      locale: { en: en, ja: ja }[language.substr(0, 2)],
                    })
                  : " - " + mapi18n("form_lbl_seldate")
              }`}</span>
            )}
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </HeaderInternal>
        </Header>
        {open && (
          <Body>
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              min={min}
              max={max}
            />
            <Button
              secondary
              loading={waiting === "date_range"}
              label="Apply"
              onClick={apply}
            />
          </Body>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-width: 312px;
  width: fit-content;
  z-index: var(--z-index-date-picker);
`;

const Header = styled.div`
  width: 100%;
  cursor: pointer;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  padding: 8px;
  ${(p) =>
    p.open
      ? "border-top: 1px solid var(--color-primary);border-left: 1px solid var(--color-primary);border-right: 1px solid var(--color-primary);"
      : "&:hover { background-color: var(--color-hover-opacity); }"}
`;

const HeaderInternal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid gray;
  padding: 1rem;
  > span {
    user-select: none;
    font-weight: 700;
    font-size: var(--font-size-5);
    margin-right: 1rem;
  }
`;

const Body = styled.div`
  position: absolute;
  width: 100%;

  border-left: 1px solid var(--color-primary);
  border-right: 1px solid var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
  user-select: none;
  background-color: var(--color-paper);
  box-shadow: var(--box-shadow-3);
`;
