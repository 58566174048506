import { useContext, useEffect, useState } from 'react'

import Loading from 'components/Loading'
// Components
import Modal from 'components/Modal'
// Context
import { LocalizationContext } from 'context/Localization'
import { ModalProvider } from 'context/Modal'
import Notifications, { NotificationsContext } from 'context/Notifications'
// MapApi
import mapDashboardResponse from 'mapApi/mapDashboardResponse'
import mapDatesResponse from 'mapApi/mapDatesResponse'
import mapFilterlistResponse from 'mapApi/mapFilterlistResponse'
import mapFilterResponse from 'mapApi/mapFilterResponse'
import mapLoginResponse from 'mapApi/mapLoginResponse'
import mapReportTypesResponse from 'mapApi/mapReportTypesResponse'
import DashboardPage from 'pages/DashboardPage'
// Pages
import LoginPage from 'pages/LoginPage'
import styled from 'styled-components'
// import { defaultGet, defaultPost } from "tools/axios";
import { useAxios } from 'tools/axios'
// Tools
import { binarify, objectToFormData } from 'tools/misc'
import useLocalStorage from 'tools/useLocalStorage'
import { xmlToObject } from 'tools/xml'

function App() {
  const { addNotification } = useContext(NotificationsContext)
  const {
    interfaceMapping,
    setInterfaceMapping,
    setAvailableEncodings,
    setAvailableLanguages,
    setLanguage,
    mapi18n,
  } = useContext(LocalizationContext)
  const { defaultGet, defaultPost } = useAxios()

  const [username, setUsername] = useState(null)
  const [loggedIn, setLoggedIn] = useState(false)
  const [reportTypes, setReportTypes] = useState(null)
  const [timeframes, setTimeframes] = useState(null)
  const [dateLimits, setDateLimits] = useState(null)
  const [dashboard, setDashboard] = useState(null)
  const [filters, setFilters] = useState(null)
  const [filterlist, setFilterlist] = useState([])

  const [loginCache, setLoginCache] = useLocalStorage('loginCache', null)

  const loginSetter = (loginObject) => {
    setInterfaceMapping(loginObject ? loginObject.interfaceMapping : null)
    setUsername(loginObject ? loginObject.username : null)
    setLanguage(loginObject ? loginObject.language : null)
    setAvailableEncodings(loginObject ? loginObject.availableEncodings : null)
    setAvailableLanguages(loginObject ? loginObject.availableLanguages : null)
  }

  const login = async (username, password) => {
    if (username && password) {
      try {
        const response = await defaultPost(
          'auth/in/',
          objectToFormData({ username, password })
        )
        const data = await xmlToObject(response.data)
        const loginObject = mapLoginResponse(data)
        setLoginCache(loginObject)
        loginSetter(loginObject)
        setLoggedIn(true)
      } catch (err) {
        addNotification(
          'error',
          'Error logging in. Please try again or contact your administrator'
        )
        console.error(err)
      }
    } else {
      addNotification('error', 'Please enter both a username and a password')
    }
  }

  const logout = async () => {
    try {
      await defaultPost('auth/out/')
      // clear state
      setLoggedIn(false)
      setUsername(null)
      setReportTypes(null)
      setTimeframes(null)
      setDateLimits(null)
      setDashboard(null)
      setFilters(null)
      setLoginCache(null)
      addNotification('notification', 'Success')
    } catch (err) {
      addNotification(
        'error',
        'An error occurred during logout. Please try again.'
      )
      console.error(err)
    }
  }

  const checkLogin = async () => {
    try {
      const loginCheck = await defaultGet('auth/check/')
      const checkObject = await xmlToObject(loginCheck.data)
      if (checkObject.login.$.valid === 'True' && loginCache) {
        loginSetter(loginCache)
        setLoggedIn(true)
      } else {
        addNotification('warning', 'Not currently logged in')
      }
    } catch (err) {
      addNotification('error', 'Error checking login')
      console.error(err)
    }
  }

  const loadDates = async () => {
    try {
      const datesRequest = await defaultPost(
        'product/dates/read/',
        objectToFormData({ product_id: 'si' })
      )
      const datesFromXml = await xmlToObject(datesRequest.data)
      const datesObject = mapDatesResponse(datesFromXml)
      setTimeframes(datesObject.timeframes)
      setDateLimits(datesObject.dateLimits)
    } catch (err) {
      addNotification('error', 'Error loading Dates')
      console.error(err)
    }
  }

  const loadReportTypes = async () => {
    try {
      const reportTypesRequest = await defaultPost(
        'product/report/read/',
        objectToFormData({ product_id: 'si' })
      )
      const reportTypesFromXml = await xmlToObject(reportTypesRequest.data)
      const reportTypesArray = mapReportTypesResponse(reportTypesFromXml)
      setReportTypes(reportTypesArray)
    } catch (err) {
      addNotification('error', 'Error loading Report Types')
      console.error(err)
    }
  }

  const loadDashboard = async () => {
    try {
      const dashboardRequest = await defaultPost(
        'report/dashboard/read/',
        objectToFormData({ product_id: 'si' })
      )
      const dashboardFromXml = await xmlToObject(dashboardRequest.data)
      const dashboardObject = mapDashboardResponse(dashboardFromXml)
      setDashboard(dashboardObject)
    } catch (err) {
      addNotification('error', 'Error loading Dashboard')
      console.error(err)
    }
  }

  const loadFilters = async () => {
    try {
      const filtersRequest = await defaultPost(
        'product/filter/read/',
        objectToFormData({ product_id: 'si' })
      )
      const filtersFromXml = await xmlToObject(filtersRequest.data)
      const filtersObject = mapFilterResponse(filtersFromXml)
      setFilters(filtersObject)
    } catch (err) {
      addNotification('error', 'Error loading Filters')
      console.error(err)
    }
  }

  const loadFilterlist = async () => {
    try {
      const filterlistRequest = await defaultPost(
        'preferences/filterlist/read/',
        objectToFormData({ product_id: 'si' })
      )
      const filterlistFromXml = await xmlToObject(filterlistRequest.data)
      const filterlistObject = mapFilterlistResponse(filterlistFromXml)
      console.log(filterlist)
      setFilterlist(filterlistObject)
    } catch (err) {
      addNotification('error', 'Error loading Filterlist')
      console.error(err)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (loggedIn) {
        // Dates
        await loadDates()

        // Report Types
        await loadReportTypes()

        // Filterlist
        await loadFilterlist()

        // Filter
        await loadFilters()

        // Dashboard
        await loadDashboard()
      } else {
        await checkLogin()
      }
    })()
  }, [loggedIn])

  const loaded = () => {
    // console.log(
    //   binarify(
    //     !!username,
    //     !!reportTypes,
    //     !!interfaceMapping,
    //     !!timeframes,
    //     !!dateLimits,
    //     !!filters
    //   ).toString(2)
    // )
    return (
      binarify(
        !!username,
        !!reportTypes,
        !!interfaceMapping,
        !!timeframes,
        !!dateLimits,
        !!filters
      ) === 0b111111
    )
  }

  return (
    <>
      <ModalProvider>
        {!loggedIn ? (
          <LoginPage login={login} />
        ) : (
          <>
            {loaded() ? (
              <DashboardPage
                mapi18n={mapi18n}
                username={username}
                dashboard={dashboard}
                setDashboard={setDashboard}
                reportTypes={reportTypes}
                interfaceMapping={interfaceMapping}
                timeframes={timeframes}
                dateLimits={dateLimits}
                loadDashboard={loadDashboard}
                logout={logout}
                filters={filters}
                filterlist={filterlist}
                loadFilterlist={loadFilterlist}
              />
            ) : (
              <LoadingZone>
                <Loading />
                <span>Loading the dashboard...</span>
              </LoadingZone>
            )}
          </>
        )}

        <Notifications />
        <Modal />
      </ModalProvider>
    </>
  )
}

export default App

const LoadingZone = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
