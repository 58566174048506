import format from 'date-fns/format'

// Form data helpers

/**
 * Converts Object to FormData
 * @param {object} data
 * @returns {FormData}
 */
export const objectToFormData = (data) => {
  let formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value)
  }
  return formData
}

/**
 * console.log() FormData
 * @param {formData} formData
 */

export const logFormData = (formData) => {
  for (var pair of formData.entries()) {
    console.log(pair[0] + ', ' + pair[1])
  }
}

/**
 * Converts list of booleans into a number via binary representation
 * @param  {...any} booleans
 * @returns {number}
 */

export const binarify = (...booleans) => {
  return booleans.reduce((res, x) => (res << 1) | x)
}

export const metrixDateToJsDate = (date) => {
  // metrix date formate = YYYYMMDD
  return Date.parse(
    date.substring(0, 4) +
      '-' +
      date.substring(4, 6) +
      '-' +
      date.substring(6, 8)
  )
}

export const formatDate = (date) => {
  return format(date, 'yyyyMMdd')
}

export const transpose = (a) => {
  // Calculate the width and height of the Array
  var w = a.length || 0
  var h = a[0] instanceof Array ? a[0].length : 0

  // In case it is a zero matrix, no transpose routine needed.
  if (h === 0 || w === 0) {
    return []
  }

  /**
   * @var {Number} i Counter
   * @var {Number} j Counter
   * @var {Array} t Transposed data is stored in this array.
   */
  var i,
    j,
    t = []

  // Loop through every item in the outer array (height)
  for (i = 0; i < h; i++) {
    // Insert a new row (array)
    t[i] = []

    // Loop through every item per item in outer array (width)
    for (j = 0; j < w; j++) {
      // Save transposed data.
      t[i][j] = a[j][i]
    }
  }

  return t
}

export async function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const numberWithCommas = (x) => {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return x
}
