import { useContext, useState } from 'react'

import Button from 'components/Button'
import { LocalizationContext } from 'context/Localization'
import { ModalContext } from 'context/Modal'
import { NotificationsContext } from 'context/Notifications'
// react select onChange returns array for multi, object for single
import Select from 'react-select'
import styled from 'styled-components'
import { api } from 'tools/axios'
import { objectToFormData } from 'tools/misc'

import { faDownload } from '@fortawesome/free-solid-svg-icons'

const triggerDownload = (data, extension, fileName) => {
  let url
  if (extension === 'html') {
    url = window.URL.createObjectURL(new Blob([data]))
  } else {
    url = window.URL.createObjectURL(new Blob([data]))
  }

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.${extension}`) //or any other extension
  document.body.appendChild(link)
  link.click()
  window.URL.revokeObjectURL(url)
}

// uses combination of parent options and drilldownoptions to make a report
const ExportFilterDefs = ({ username }) => {
  const { closeModal } = useContext(ModalContext)
  const { mapi18n } = useContext(LocalizationContext)
  const { addNotification } = useContext(NotificationsContext)
  const [selected, setSelected] = useState()
  const [waiting, setWaiting] = useState(null)
  const [options, _] = useState([
    { value: 'xls', label: 'xls' },
    { value: 'csv', label: 'csv' },
  ])

  // allowed xls, html,
  const handleSubmit = async () => {
    if (selected) {
      try {
        setWaiting('submit')
        const requestObject = { product_id: 'si', export_type: selected }
        const response = await api({
          method: 'post',
          url: 'product/filterdef/read/',
          data: objectToFormData(requestObject),
          headers: { 'Content-Type': 'multipart/form-data' },
          responseType: 'arraybuffer',
        })
        triggerDownload(response.data, selected, `filterdefs_${username}`)
        closeModal()
      } catch (err) {
        console.error(err)
      }
    } else {
      addNotification('warning', 'Select format')
    }
  }

  const handleTypeChange = (value) => {
    setSelected(value.value)
  }

  return (
    <Container>
      <StyledSelect
        options={options}
        onChange={handleTypeChange}
      ></StyledSelect>
      <ActionRow>
        <Button tertiary label={mapi18n('gen_btn_can')} onClick={closeModal} />
        <Button
          primary
          loading={waiting === 'submit'}
          icon={faDownload}
          onClick={handleSubmit}
        />
      </ActionRow>
    </Container>
  )
}

export default ExportFilterDefs

const Container = styled.div`
  place-items: center;
  width: 40vw;
`

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`

const StyledSelect = styled(Select)`
  min-width: 35vw;
`
